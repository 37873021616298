/*
This module is responsible for controlling the functionality of the header
<>
The header is statically positioned by default, however we are using a package called @stickybits
to assign position sticky and fallbacks for browsers that need them
<>

*/
import stickybits from 'stickybits'

import { getElement } from './dom';

const header = getElement('header#header');

if (header) {
  stickybits(header);
}
