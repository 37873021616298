import { ready, getElement } from './dom';

const requestATitle = getElement('section.request-a-title');

const toggleISBN = () => {
    const dropdownFormat = getElement('select#format');
    const sptableInput = getElement('#sptable');

    const isbn = getElement('.isbnField');
    const isbnInput = getElement('#isbn');

    isbn.style.display = 'none';
    isbnInput.required = false;

    dropdownFormat.addEventListener('change', function (e) {
        let selectedFormatOption = $(dropdownFormat).find(':selected');

        if (selectedFormatOption.length > 0) {
            sptableInput.value = selectedFormatOption.attr('data-sptable');

            if (selectedFormatOption.attr('data-isbn') === "True") {
                isbn.style.display = 'block';
                isbnInput.required = true;
            } else {
                isbn.style.display = 'none';
                isbnInput.required = false;
                isbnInput.value = '';
            }
        }
    });
};

function main() {
  toggleISBN();
}

if (requestATitle) {
  ready(main);
}
