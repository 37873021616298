
import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/keyboardnav';

export default class TPSUtilities {

    constructor() {

        this.window = $(window);
        this.document = $(document);
       
    }

    init() {
        const _self = TPS.Utilities;

        // check if there is a page
        // anchor in the URL on load
        if (location.hash) {

            // we are going to setup an interval
            // to check if the webfonts are loaded
            // b/c they will effect the scroll height
            // of elements on the page after load
            const intervalCheck = setInterval(() => {

                if (document.fonts.status === 'loaded') {
                    // the fonts are loaded so
                    // we can stop the interval
                    clearInterval(intervalCheck);
                    // now we will check if
                    // there is a page anchor in the URL and scroll to it
                    const _hash = location.hash;
                    const __hashTarget = document.querySelector(_hash);
                    if (__hashTarget) {
                        const _topScroll = _self.getScrollToPosition(__hashTarget);
                        _self.smoothScrollTo(_topScroll, 500);
                    }



                }
            }, 100);

            
        }

    }

    getScrollToPosition(_target) {
        const _self = TPS.Utilities;

        const _header = document.querySelector('#header');

        const _headerHeight = _header.offsetHeight;
        const _topScroll = _target.getBoundingClientRect().top + window.scrollY - _headerHeight;

        return _topScroll;
    }

    smoothScrollOnClick(e) {
        const _self = TPS.Utilities;
        const _elem = e.target;
        const _elemHref = _elem.getAttribute('href');
        const _target = document.querySelector(_elemHref);

        const _topScroll = _self.getScrollToPosition(_target);

        _self.smoothScrollTo(_topScroll, 500);

    }

    // Polyfilled smooth scrolling for IE, Edge & Safari
    smoothScrollTo(to, duration) {
        const element = document.scrollingElement || document.documentElementl;
        const start = element.scrollTop;
        const change = to - start;
        const startDate = +new Date();

        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };

        const animateScroll = _ => {
            const currentDate = +new Date();
            const currentTime = currentDate - startDate;
            element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));

            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            } else {
                element.scrollTop = to;
            }
        };

        animateScroll();
    }
    
    updateUrlParameter(uri, key, value) {
        // remove the hash part before operating on the uri
        var i = uri.indexOf('#');
        var hash = i === -1 ? '' : uri.substr(i);
        uri = i === -1 ? uri : uri.substr(0, i);

        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";

        if (!value) {
            // remove key-value pair if value is empty
            uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
            if (uri.slice(-1) === '?') {
                uri = uri.slice(0, -1);
            }
            // replace first occurrence of & by ? if no ? is present
            if (uri.indexOf('?') === -1) {
                uri = uri.replace(/&/, '?');
            }
        } else if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            uri = uri + separator + key + "=" + value;
        }
        return uri + hash;
    }

    addDatePickerToInput(_selector) {
        if (!_selector)
            return;


        const _self = TPS.Utilities;
        const _input = document.querySelector(_selector);

        const _litepicker = _self.initLitePicker(_input);

        console.log(_selector);
        console.log(_input);


    }

    initLitePicker(_elem) {

        const _parent = _elem.parentElement;
        _parent.classList.add('tps-calendar-wrapper');

        return new Litepicker({
            element: _elem,
            parentEl: _parent,
            format: 'DD/MM/YYYY',
            plugins: ['keyboardnav'],
            autoRefresh: true,
            autoApply: true,
            firstDay: 0,
            position: 'bottom',
            zIndex: 999
        });

    }
}
