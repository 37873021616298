

export default class TPSFederatedSearch {

    constructor() {

        this.window = $(window);
        this.document = $(document);
        this.props = {};
        this.searchTerm = '';
    }

    // called on dom ready
    //init() { }

    // methds can be invoked using TPS.FederatedSearch.<method name>

    getSearchResults(_props) {

        if (!_props || typeof _props !== 'object')
            return;

        let _self = TPS.FederatedSearch;

        _self.props = _props;

        _self.searchTerm = _self.getSearchTerm();

        _self.props.searchParams.search = _self.santizeSearchTerm(_self.searchTerm);
  

        $.each(_self.props.buckets, function (_k, _v) {
            _self.searchRequest(_k)
        });

    }

    searchRequest(_indexKey) {

        if (!_indexKey)
            return;

        const _self = TPS.FederatedSearch;
        const _url = `${_self.props.accessUrl}${_self.props.buckets[_indexKey].indexName}/docs/search?api-version=2020-06-30-Preview`;


        if (_indexKey === 'communico') {
            const _now = new Date();
            _now.setHours(0, 0, 0, 0);
            _self.props.searchParams.filter = `EventPrivate eq false and EventModified eq 'not_modified' and EventStart ge ${_now.toISOString()}  and (EventPublishDate eq null or EventPublishDate le ${_now.toISOString()})`;
            _self.props.searchParams.orderby = `search.score() desc, EventStart asc`;
        } else {
            _self.props.searchParams.filter = null;
            _self.props.searchParams.orderby = `search.score() desc`;
        }


        const _settings = {
            "crossDomain": true,
            "url": _url,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "api-key": _self.props.key,
                "Cache-Control": "no-cache",
            },
            "data": JSON.stringify(_self.props.searchParams)
        }
                
        $.ajax(_settings).done(function (response, xhr) {
            //_self.local.totalResults = _self.props.count && response['@odata.count'] ? response['@odata.count'] : -1;
            //_self.local.totalPages = Math.ceil(local.totalResults / ls.results.pager.pageSize);
            if (xhr === 'success') {
                
                if (response['@odata.count'] > 0) {
                    _self.showResults(response, _indexKey);
                } else {
                    _self.showNoResults(_indexKey);
                }
            }
            //ls.onResults.call(response, local);
        }).fail(function (response) {
            //console.error('fail');
            console.warn(response);
            _self.showNoResults(_indexKey);
        });;
    }

    showResults(_data, _indexKey) {

        if (!_indexKey)
            return;

        const _self = TPS.FederatedSearch;
        const _target = $(`#${_indexKey}-items`);

        const _resultsHeader = _self.createResultsHeader(_indexKey);

        const _items = _self.createResultsItems(_data['value'], _indexKey)
        const _resultsActions = _self.createResultsActions(_data['@odata.count'], _indexKey);


        _target.before(_resultsHeader, _resultsActions);
        _target.empty().append(_items);
        _target.attr('aria-busy', 'false');
        _target.after(_resultsActions);

    }

    createResultsHeader(_indexKey) {

        if (!_indexKey)
            return;

        const _self = TPS.FederatedSearch;
        const _resultsText = _self.decodeHtmlText(_self.props.buckets[_indexKey].labels.results);

        const _html = `<div class="tps-federated-search-section-results-text">${_resultsText}</div>`;

        return _html;

    }
    
    createResultsActions(_count, _indexKey) {
        if (!_indexKey)
            return;

        const _self = TPS.FederatedSearch;

        const _countText = _count > 5 ? `1-5 of ${_count} results` : `1-${_count} of ${_count} results`;
        const _termText = _self.searchTerm !== '' && _self.searchTerm !== '*' ? `for <strong>${_self.escapeSearchText(_self.searchTerm)}</strong>` : ``;
        
        let _actionLinkUrl = _self.props.buckets[_indexKey].seeAllUrl.replace('{q}', encodeURIComponent(_self.searchTerm));

        if (_self.searchTerm === '' || _self.searchTerm === '*') {
            _actionLinkUrl = _self.props.buckets[_indexKey].searchOnlyUrl;
        }

        const _actionLink = _count > 5 ? `<a href="${_actionLinkUrl}" target="_blank">${_self.props.buckets[_indexKey].labels.seeAll}</a>` : ``;

        const _html = `
                <div class="tps-federated-search-section-results-actions">
                    <div class="tps-federated-search-section-results-actions-count">
                        ${_countText} ${_termText}
                    </div>
                    <div class="tps-federated-search-section-results-actions-link">
                        ${_actionLink}
                    </div>
                </div>`;
        return _html;
    }

    createResultsItems(_data, _indexKey) {

        if (!_indexKey)
            return;

        const _self = TPS.FederatedSearch;        
        
        switch (_indexKey) {
            case 'sirsi':
                return _self.createResultsItems_sirsi(_data);
            case 'communico':
                return _self.createResultsItems_communico(_data);
            case 'hip':
                return _self.createResultsItems_hip(_data);
            case 'opl':
                return _self.createResultsItems_opl(_data);
            default:
                return '';
        }
        
    }

    createResultsItems_sirsi(_data) {
        const _self = TPS.FederatedSearch;
        let _html = '';
        

        $.each(_data, function (k, v) {

            const _authors = v.mediaauthors ? `<div class="tps-federated-search-section-results-item-authors">By ${v.mediaauthors}</div>` : '';

            const _date = v.mediapublicationdate ? `<div class="tps-federated-search-section-results-item-year"><strong>Year:</strong> ${v.mediapublicationdate}</div>` : ''


            const _subtitle = v.subtitle ? `<div class="tps-federated-search-section-results-item-subtitle">${v.subtitle}</div>` : '';

            const _formatText = v.mediaformatname ? `<div class="tps-federated-search-section-results-item-format"><strong>Format:</strong> ${v.mediaformatname}</div>` : '';

            let _img = '';
            let _img_bg = '';

            // if the image field is empty in the index
            // set the default right away
            if (!v.image) {
                const _img_default = _self.setDefaultImage(v.mediaformatid);
                if (_img_default) {
                    _img_bg = ` style="background-image: url('${_img_default}');"`;
                    _img = `<img src="${_img_default}" class="tps-seo-image show-for-sr" alt="" />`;
                }
            }

            _html += `
                <a href="${v.url}" target="_blank" class="tps-federated-search-section-results-item sirsi">
                    <div class="tps-federated-search-section-results-item-image-wrapper">
                        <div class="tps-federated-search-section-results-item-image" id="item-img-${v.internalid}"${_img_bg}>${_img}</div>
                    </div>
                    <div class="tps-federated-search-section-results-item-details">
                        <div class="tps-federated-search-section-results-item-title">${v.title}</div>
                        ${_subtitle}
                        ${_authors}

                        <div class="tps-federated-search-section-results-item-details-inner">
                            ${_formatText}
                            ${_date}
                        </div>
                        <div class="tps-federated-search-section-results-item-availability"><strong>Availability:</strong> ${v.mediaavailability}</div>
                        <div class="tps-federated-search-section-results-item-link">View Details</div>
                    </div>
                </a>
            `;

            // if the image field is not empty in the index
            // check if the image is real
            if (v.image) {
                _self.checkItemImage(v.image, v.mediaformatid, `#item-img-${v.internalid}`);
            }

        });


        return _html;

    }

    createResultsItems_communico(_data) {
        const _self = TPS.FederatedSearch;  
        let _html = '';
        
        $.each(_data, function (k, v) {
            const _img = v.image ? v.image : _self.props.buckets.communico.defaultImage;
            const _startDate = new Date(v.EventStart);
            const _eventDate = TPS.AzureSearch.__helperEventDateString(v);

            const _location = v.EventLocationName;
            const _roomName = v.EventRoomName;
            const _locationText = _roomName ? `${_location} - ${_roomName}` : _location;
            const _subTitle = v.subtitle ? `<div class="tps-azure-search-results-item-subtitle">${v.subtitle}</div>` : '';
            const _ages = v.EventAges.length > 0 ? v.EventAges.toString().replace(',', ', ') : '';
            const _agesText = _ages != '' ? `<div class="tps-federated-search-section-results-item-ages"><strong>Ages:</strong> ${_ages}</div>` : '';
            const _type = v.EventType.length > 0 ? v.EventType.toString().replace(',', ', ') : '';

            //const _typeText = _type != '' ? `<div class="tps-federated-search-section-results-item-type"><strong>Event Type:</strong> ${_type}</div>` : '';


            _html += `
                <a href="${v.url}" target="_blank" class="tps-federated-search-section-results-item event">
                    <div class="tps-federated-search-section-results-item-image-wrapper">
                        <div class="tps-federated-search-section-results-item-image" style="background-image: url('${_img}');">
                            <img src="${_img}" class="tps-seo-image show-for-sr" alt="" />
                        </div>
                    </div>
                    <div class="tps-federated-search-section-results-item-details">
                        <div class="tps-federated-search-section-results-item-title">${v.title}</div>
                        ${_subTitle}
                        <div class="tps-federated-search-section-results-item-date" data-date="${_startDate.toLocaleTimeString()}">${_eventDate}</div>
                        <div class="tps-federated-search-section-results-item-location">${_locationText}</div>
                        ${_agesText}
                        <div class="tps-federated-search-section-results-item-link">View the Event</div>
                    </div>
                </a>
            `;
        });

        return _html;
    }

    createResultsItems_hip(_data) {
        const _self = TPS.FederatedSearch;  

        let _html = '';

        $.each(_data, function (k, v) {
            _html += `
                <a href="${v.url}" target="_blank" class="tps-federated-search-section-results-item hip">
                     <div class="tps-federated-search-section-results-item-details">
                        <div class="tps-federated-search-section-results-item-title">${v.title}</div>
                        <div class="tps-federated-search-section-results-item-description">${v.description}</div>
                        <div class="tps-federated-search-section-results-item-link">View this resource</div>
                    </div>
                </a>
            `;

        });

        return _html;
    }

    createResultsItems_opl(_data) {
        const _self = TPS.FederatedSearch;
        // a URL fix for when the MVC app is not deployed on the root
        // get the URL base and remove the trialing slash
        const _urlBase = _self.props.buckets.opl.urlBase.slice(0, -1);
        let _html = '';

        $.each(_data, function (k, v) {

            _html += TPS.AzureSearch.renderOplResult(v, _urlBase);
            
        });

        return _html;
    }
    
    showNoResults(_indexKey) {

        if (!_indexKey)
            return;

        const _self = TPS.FederatedSearch;
        const _target = $(`#${_indexKey}-items`);
        const _termText = _self.searchTerm ? _self.searchTerm : "\"\"";
        const _html = `
                <div class="tps-federated-search-section-results-no-results">${_self.props.buckets[_indexKey].labels.noResults.replace('{q}', `<strong>${_self.escapeSearchText(_termText)}</strong>`)}</div>
            `;


        const _actionLinkUrl = _self.props.buckets[_indexKey].searchOnlyUrl;
        const _actionLink = `<a href="${_actionLinkUrl}" target="_blank">${_self.props.buckets[_indexKey].labels.searchOnly}</a>`;

        const _html_action = `
                <div class="tps-federated-search-section-results-actions">
                    <div class="tps-federated-search-section-results-actions-link">
                        ${_actionLink}
                    </div>
                </div>`;


        _target.empty();
        _target.append(_html);
        _target.after(_html_action);
        _target.attr('aria-busy', 'false');

    }
    
    decodeHtmlText(_text) {
        return $('<textarea />').html(_text).text();
    }

    getSearchTerm() {

        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split(/(\&){1}(?!\&)/gi),
            sParameterName,
            term = '*',
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === 'q') {
                term = sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1].replace(/\+/g, " "));
            }
        }

        return term;

    }

    escapeSearchText(_text) {
        return $('<textarea/>').text(_text).html();
    }

    santizeSearchTerm(_term) {

        const _self = TPS.FederatedSearch;
        let termText = '';
        
        if (_term) {

            const _searchTerm = _term.trim().replace(/[\\]{1}/gi, '\\').replace(/[\"]{1}/gi, '\$&').replace(/[\~\?\*\:\/\^\[\]\(\)\{\}\!\+\-]{1}/gi, ' ').replace(/(\s\&\s)/gi, ' \\& ').replace(/(\&){2}/gi, '\\$&').replace(/(\|){2}/gi, '\\$&').trim();

            // the term text will start with just
            // a basic term search which special characters
            // escaped
            termText = `(${_searchTerm})`;

            // if the search text is not a phrase (inside quotes)
            // then we can apply other search methods
            if (_term.indexOf('"') === -1) { 

                // Check if the term starts with a quotation mark
                // and if not, apply quotes and use proximity search
                if (_self.props.searchParams.queryType === 'full' && _self.props.useTermProximity) {
                    const _proximitySearchTerm = `\"${_searchTerm}\"~${_self.props.termProximityDistance}`;
                    termText += ` | (${_proximitySearchTerm})`;
                }

                // add fuzzy search results
                if (_self.props.searchParams.queryType === 'full' && _self.props.isFuzzy && _self.props.fuzzyProximity > 0) {
                    const _fuzzySearchTerm = _searchTerm.replace(/[\s^]+(?=([^\"]*\"[^\"]*\")*[^\"]*$)(?=([^\']*\'[^\']*\')*[^\']*$)/gi, `~${_self.props.fuzzyProximity} `) + `~${_self.props.fuzzyProximity}`;
                    termText += ` | (${_fuzzySearchTerm})`;

                }

                // add wildcard or prefix search results
                if (_self.props.isWildcard) {
                    const _wildcardSearchText = _searchTerm.replace(/[\s^]+(?=([^\"]*\"[^\"]*\")*[^\"]*$)(?=([^\']*\'[^\']*\')*[^\']*$)/gi, '* ') + '*';

                    termText += ` | (${_wildcardSearchText})`;
                }

            
            }

        }

        return termText;

    }

    checkItemImage(_imgSrc, _format, _elem) {
        
        const _self = TPS.FederatedSearch;
        const _img = new Image();

        _img.src = _imgSrc;
        // fetch the image
        _img.onload = function () {

            let _img_src = _imgSrc;
            // check if the image is not a 1 x 1 pixel
            if (_img.width <= 1 || _img.height <= 1) {
                    
                _img_src = _self.setDefaultImage(_format);
            }

            if (_img_src) {
                $(_elem).attr('style', `background-image: url('${_img_src}');`);
                $(_elem).append(`<img src="${_img_src}" class="tps-seo-image show-for-sr" alt="" />`);
            }

        }


    }

    setDefaultImage(_format) {

        const _self = TPS.FederatedSearch;
        const _bookFormats = ['BB', 'BOOK', 'EBOOK', 'EMAG', 'EREF', 'GR', 'KIT', 'LP', 'MAG', 'MAP', 'PB', 'UNKNOWN'];
        const _moviesFormats = ['BLURAY', 'DVD', 'EVIDEO', 'GAME', 'MISC'];
        const _musicFormats = ['BCD', 'CD', 'EAUDIO', 'EMUSIC', 'MP3', 'TB'];

        // check what format group, to choose the default image
        const _img_grp = _bookFormats.indexOf(_format) > -1 ? 'BOOKS' : _moviesFormats.indexOf(_format) > -1 ? 'MOVIES' : _musicFormats.indexOf(_format) > -1 ? 'MUSIC' : '';

        let _img_src = '';

        // if the item does not have an image in the index
        // and its format is in one of the above groups
        // then show the default image configured in kentico
        if (_img_grp) {
            _img_src = _self.props.buckets.sirsi.defaultImages[_img_grp] ? _self.props.buckets.sirsi.defaultImages[_img_grp] : '';
        }

        return _img_src;

    }
};
