import { slideToggle } from './slidetoggle';
import { ready, getElement, getElements } from './dom';

const shortcuts = getElement(`[data-click="shortcut-links"]`);
const quickLinks = getElement(`[data-click="quick-links"]`);
const dropdowns = getElements(`[data-click^="dropdown"]`);
const tabletMediaQuery = window.matchMedia('(max-width: 1023px)');
const topArticleAccordion = getElement(`[data-click="news-top-articles"]`);
const topLanguageAccordion = getElement(`[data-click="top-language-list"]`);
const accordions = getElements('[data-click^="accordion"]');
const tabletAccordions = getElements('[data-click*="tablet-accordion"]');

function verifyKey(e) {
    if (e.which === 32 || e.which === 13) {
        e.preventDefault();
        this.click();
    }
}

function handleDropdownClick(e) {
    e.preventDefault();
    let clicked = e.target;
    const dropdownName = clicked.getAttribute('data-click');
    if (!dropdownName) return;

    const target = document.querySelector(`[data-for="${dropdownName}"]`);
    const toggles = document.querySelectorAll(`[data-click="${dropdownName}"]`);

    slideToggle(target, {
        callback: isVisible => {
            if (isVisible) {
                toggles.forEach(toggle => {
                    toggle.setAttribute('aria-expanded', 'true');
                    toggle.classList.add('js-is-active');
                });
                target.classList.add('js-is-visible');
                if (clicked.getAttribute('data-focus') && getElement(`#${clicked.getAttribute('data-focus')}`)) {
                    getElement(`#${clicked.getAttribute('data-focus')}`).focus();
                }
            } else {
                toggles.forEach(toggle => {
                    toggle.setAttribute('aria-expanded', 'false');
                    toggle.classList.remove('js-is-active');
                });
                target.classList.remove('js-is-visible');
                if (clicked.getAttribute('data-focus')) clicked.focus();
            }
        },
    });
}

const handler = () => {
    dropdowns.forEach(dropdown => dropdown.addEventListener('click', handleDropdownClick));

    if (shortcuts) {
        // eslint-disable-next-line no-new
        new Accordion(shortcuts, slideToggle, tabletMediaQuery);
    }
    if (quickLinks) {
        // eslint-disable-next-line no-new
        new Accordion(quickLinks, slideToggle, tabletMediaQuery);
    }
    if (topArticleAccordion) {
        // eslint-disable-next-line no-new
        new Accordion(topArticleAccordion, slideToggle, tabletMediaQuery);
    }
    if (topLanguageAccordion) {
        // eslint-disable-next-line no-new
        new Accordion(topLanguageAccordion, slideToggle, tabletMediaQuery);
    }
    if (accordions.length >= 1) {
        accordions.forEach(a => new Accordion(a, slideToggle));
    }
    if (tabletAccordions.length >= 1) {
        tabletAccordions.forEach(a => new Accordion(a, slideToggle, tabletMediaQuery));
    }
};

if (dropdowns) ready(handler);

class Accordion {
    constructor(el, callback, breakpoint) {
        this.el = el;
        this.callback = callback;
        this.breakpoint = breakpoint;
        this.focus = false;
        this.init();
    }

    init() {
        if (this.breakpoint) {
            this.breakpoint.addListener(() => this.matchMedia(this.breakpoint));
            this.matchMedia(this.breakpoint);
        } else {
            this.el.addEventListener('click', this.toggle);
            this.el.addEventListener('keydown', verifyKey);
        }
        this.target = document.querySelector(`[data-for="${this.el.dataset.click}"]`);
        this.focus = this.el.dataset.focus ? this.el.dataset.focus : false;
    }

    toggle = e => {
        e.preventDefault();
        this.callback(this.target, {
            callback: isVisible => {
                if (isVisible) {
                    this.setClose();
                } else {
                    this.setOpen();
                }
            },
        });
    };

    focus = () => {
        if (!this.focus) return;
        const target = document.querySelector(`${this.focus}`);
        target.focus();
    };

    setClose = () => {
        this.el.setAttribute('aria-expanded', 'true');
        this.el.classList.add('js-is-active');
        this.target.classList.add('js-is-visible');
    };

    setOpen = () => {
        this.el.setAttribute('aria-expanded', 'false');
        this.el.classList.remove('js-is-active');
        this.target.classList.remove('js-is-visible');
    };

    matchMedia = (breakpoint = this.breakpoint) => {
        if (breakpoint.matches) {
            this.el.addEventListener('click', this.toggle);
            this.el.addEventListener('keydown', verifyKey);
        } else {
            this.el.removeEventListener('click', this.toggle);
            this.el.removeEventListener('keydown', verifyKey);
        }
    };
}
