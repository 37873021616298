
import TPSFederatedSearch from './_tps-federated-search';
import TPSAzureSearch from './_tps-azure-search';
import TPSResourcesSearch from './_tps-resources-search';
import TPSOffCanvas from './_tps-off-canvas';
import TPSUtilities from './_tps-utilities';
import TPSEvents from './_tps-events';
import TPSMasthead from './_tps-masthead';
import TPSBackToTop from './_tps-back-to-top';
import TPSNav from './_tps-nav';
import TPSReadingLists from './_tps-reading-lists';

export default class TPS {

    constructor() {
        // All classes instances
        this.Nav = new TPSNav;
        this.FederatedSearch = new TPSFederatedSearch;
        this.AzureSearch = new TPSAzureSearch;
        this.OffCanvas = new TPSOffCanvas;
        this.ResourcesSearch = new TPSResourcesSearch;
        this.Utilities = new TPSUtilities;
        this.Events = new TPSEvents;
        this.Masthead = new TPSMasthead;
        this.BackToTop = new TPSBackToTop;
        this.ReadingLists = new TPSReadingLists;
    }

    // On DOM ready
    onDomReady() {
        const self = this;

        // On DOM ready
        document.addEventListener('DOMContentLoaded', () => {
            if (document.readyState === 'interactive' || document.readyState === 'complete') {
                self.onDomReadyCallback();
            }
        });
    }

    // On DOM ready (callback)
    onDomReadyCallback() {
        // Init before frameworks
        this.init();

    }

    // Init before frameworks
    init() {
        for (var i in this) {
            if (typeof this[i].init === 'function') {
                this[i].init();
            }
        }
    }

    // Init after frameworks
    initAfterFrameworks() {
        for (var i in this) {
            if (typeof this[i].initAfterFrameworks === 'function') {
                this[i].initAfterFrameworks();
            }
        }
    }

}
