/* eslint-disable no-console */
/* eslint-disable no-alert */
import { ready, getElement } from './dom';

const myProfile = getElement('section.my-profile');

// Function to check whether both PIN's match
const checkPassword = () => {
  const pinChange = getElement('#pin-change');
  const pinConfirm = getElement('#confirm-pin-change');
    
  // Check if PIN is entered
  if (pinChange.value === '') {
    alert('Please enter a new password.');
    return false;
  }
  // Check if confirmation PIN is entered
  else if (pinConfirm.value === '') {
    alert('Please confirm new password.');
    return false;
  }
  // If PIN's are not the same return False.
  else if (pinChange.value !== pinConfirm.value) {
    alert('Passwords do not match, please try again.');
    return false;
  }
  return true;
};

/**
 * Get phone field element, format field based on user input.
 */
const phoneField = getElement('#phone');
let phoneValueLength = 0;
let output;

const autoFormatPhone = () => {
    let shouldHaveOne = phoneField.pattern.startsWith("^1");

    if (shouldHaveOne && phoneField.value.indexOf("1(") != 0) {
        phoneField.value = "1(";
    }

    let phoneNumbers = phoneField.value.replace(/[^0-9]/g, '');

    let area = shouldHaveOne ? phoneNumbers.substr(1, 3) : phoneNumbers.substr(0, 3);
    let tel1 = shouldHaveOne ? phoneNumbers.substr(4, 3) : phoneNumbers.substr(3, 3);
    let tel2 = shouldHaveOne ? phoneNumbers.substr(7, 4) : phoneNumbers.substr(6, 4);

    if (area.length < 3) {
        output = shouldHaveOne ? `1(${area}` : `(${area}`;
    } else if (area.length === 3 && tel1.length < 3) {
        output = shouldHaveOne ? `1(${area}) ${tel1}` : `(${area}) ${tel1}`;
    } else if (area.length === 3 && tel1.length === 3) {
        output = shouldHaveOne ? `1(${area}) ${tel1}-${tel2}` : `(${area}) ${tel1}-${tel2}`;
    }

    /**
    * if deleting characters do not format field
    */
    if (phone.value.length >= phoneValueLength) {
        phoneField.value = output;
        phoneValueLength = output.length;
    } else {
        phoneValueLength = phoneField.value.length;
    }
};

if (myProfile) {
  ready(() => {
    const form = getElement('.pin-change-form');
    const pinConfirm = getElement('#confirm-pin-change');
    form.addEventListener('submit', e => {
      if (!checkPassword()) e.preventDefault();
    });
    pinConfirm.addEventListener('blur', checkPassword);
    // on keyup run auto format function on phone field
      phoneField.addEventListener('blur', autoFormatPhone);
      phoneField.addEventListener('input', autoFormatPhone);
  });
}
