import { ElementLoader } from './view-more';
import { getElement, ready, getElements } from './dom';

const list = getElement('[data-articles]');
const els = getElements('li', list);
const btn = getElement('#view-more-news');
const elsPerPage = 4;

const main = () => new ElementLoader(els, btn, elsPerPage);

if (btn) ready(main);
