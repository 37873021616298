const panels = document.querySelectorAll('.panel');

panels.forEach((panel) => {
    panel.addEventListener('mouseover', () => {
        clearTimeout(carouselTimeout);
        removeActiveClasses();
        panel.classList.add('active');
    });

    panel.addEventListener('mouseup', () => {
        clearTimeout(carouselTimeout);
        if (panel.classList.contains('active')) {
            window.location.href = panel.dataset.url;
        } else {
            removeActiveClasses();
            panel.classList.add('active');
        }
    });
});

function removeActiveClasses() {
    panels.forEach((panel) => {
        panel.classList.remove('active');
    });
}

var carouselTimeout = null;
var activeCarouselPanel = 0;
if (panels && panels.length > 0) {
    carouselTimeout = setTimeout(changeCarousel, 4000);
}
function changeCarousel() {
    var activePanel = document.querySelectorAll('.panel.active');
    removeActiveClasses();
    panels[activeCarouselPanel].classList.add('active');
    activeCarouselPanel++;
    if (activeCarouselPanel >= panels.length) {
        activeCarouselPanel = 0;
    }
    carouselTimeout = setTimeout(changeCarousel, 4000);
}
