import axios from 'axios';

const mapElement = document.getElementById('map');
const currentLocationBtns = document.querySelectorAll('.tps-locations-main-search-current');
const locationsSearchForms = document.querySelectorAll('.tps-locations-main-search-field');
const locationsSearchMessages = document.querySelectorAll('.tps-locations-main-search-message');
const branchList = document.querySelector('.tps-locations-main-list-branches');

const startLocation = { lat: 43.4643954, lng: -79.7045677 };
const mapMediaQuery = window.matchMedia('(min-width: 1024px)');

const svgMarkerPath = 'M10.5781 29.9741C10.8125 30.3843 11.2227 30.5601 11.75 30.5601C12.2188 30.5601 12.6289 30.3843 12.9219 29.9741L16.8477 24.3491C18.7812 21.5366 20.0703 19.6616 20.7148 18.6655C21.5938 17.2593 22.1797 16.0874 22.5312 15.1499C22.8242 14.2124 23 13.0991 23 11.8101C23 9.81787 22.4727 7.94287 21.4766 6.18506C20.4219 4.48584 19.0742 3.13818 17.375 2.0835C15.6172 1.0874 13.7422 0.560059 11.75 0.560059C9.69922 0.560059 7.82422 1.0874 6.125 2.0835C4.36719 3.13818 3.01953 4.48584 2.02344 6.18506C0.96875 7.94287 0.5 9.81787 0.5 11.8101C0.5 13.0991 0.617188 14.2124 0.96875 15.1499C1.26172 16.0874 1.84766 17.2593 2.78516 18.6655C3.37109 19.6616 4.66016 21.5366 6.65234 24.3491C8.23438 26.6343 9.52344 28.5093 10.5781 29.9741Z';

let map;
let geocoder;
let branchesData = {};
let infowindow;
let userMarker;

if (mapElement) {
    if (window.google && window.google.maps) initLocations();
    else window.addEventListener('onMapLoad', initLocations);
}

function initLocations() {
    /* eslint no-undef: "warn" */
    map = new google.maps.Map(mapElement, {
        center: new google.maps.LatLng(startLocation),
        zoom: mapMediaQuery.matches ? 12 : 11,
        disableDefaultUI: true,
    });

    geocoder = new google.maps.Geocoder();

    infowindow = new google.maps.InfoWindow({
        content,
    });

    axios.get(window.OPL.getBranchesURL).then(function (response) {
        let branches = response.data;
        let markers = [];

        let locationCategoryPins = window.OPL.locationCategoryPins;

        branches.forEach(branch => {

            var locationCategory = locationCategoryPins.filter(function (category) {
                return category.nodeGuid === branch.locationCategory;
            })[0];
            if (!locationCategory)
                locationCategory = { "nodeGuid": "67fd26fe-6b5b-47d7-91a8-c9fface31e02", "pinColour": "#440099" };

            const customIcon = {
                path: svgMarkerPath,
                // fillColor: branch.isExpress ? '#FF9E1B' : '#440099',
                fillColor: locationCategory.pinColour,
                fillOpacity: 1.0,
                strokeWeight: 0,
                rotation: 0,
                scale: 1,
                anchor: new google.maps.Point(12, 30)
            };

            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(branch.location),
                icon: customIcon,
                map
            });

            markers.push(marker);

            const listElement = document.querySelector('.tps-locations-main-list-branches-item[data-branch-id="' + branch.id + '"]');

            branchesData[branch.id] = {
                icon: customIcon,
                googleMarkerObject: marker,
                branchData: branch,
                listElement: listElement
            };

            marker.addListener('click', () => selectBranchElement(branch.id, true));
            listElement.addEventListener('click', () => selectBranchElement(branch.id));
        });

        currentLocationBtns.forEach(btn => btn.addEventListener('click', getCurrentLocation));
        locationsSearchForms.forEach(form => form.addEventListener('submit', searchByInput));
    }).catch(function (error) {
        console.log(error);
    });
}

function selectBranchElement(targetBranchID, pushToTop = false) {
    if (userMarker) userMarker.setMap(null);

    const previousClone = branchList.querySelector('.first-selected');
    if (previousClone) {
        previousClone.remove();
    }

    const previousHidden = branchList.querySelector('.hide');
    if (previousHidden) {
        previousHidden.classList.remove('hide');
    }

    for (const branchID in branchesData) {
        const branch = branchesData[branchID];

        const accordionButton = branch.listElement.querySelector('.tps-locations-main-list-branches-item-accordion-button');
        const accordionBody = branch.listElement.querySelector('.tps-locations-main-list-branches-item-accordion-body');

        if (parseInt(branchID, 10) === targetBranchID) {
            branch.listElement.classList.add('active');
            if (accordionButton && accordionBody) {
                accordionButton.setAttribute('aria-expanded', 'true');
                accordionButton.classList.add('js-is-active');
                accordionBody.classList.add('js-is-visible');
            }
        } else {
            branch.listElement.classList.remove('active');
            if (accordionButton && accordionBody) {
                accordionButton.setAttribute('aria-expanded', 'false');
                accordionButton.classList.remove('js-is-active');
                accordionBody.classList.remove('js-is-visible');
            }
        }

        branch.googleMarkerObject.setIcon(parseInt(branchID, 10) === targetBranchID ? null : branch.icon);
        branch.googleMarkerObject.setZIndex(parseInt(branchID, 10) === targetBranchID ? 1 : 0);
    }

    if (mapMediaQuery.matches) {
        branchList.scrollTo(0, branchesData[targetBranchID].listElement.offsetTop);
    } else if (pushToTop === true) {
        const clone = branchesData[targetBranchID].listElement.cloneNode(true);
        branchesData[targetBranchID].listElement.classList.add('hide');
        clone.classList.add('first-selected');
        clone.style.order = '-1';
        branchList.appendChild(clone);
    }

    if (map.getZoom() < 20 || map.getBounds().contains(branchesData[targetBranchID].googleMarkerObject.getPosition()) === false) {
        zoomIntoLocation(1000, branchesData[targetBranchID].branchData.location);
    }
}

function zoomIntoLocation(radius, { lat, lng }) {
    const position = new google.maps.LatLng({ lat, lng });
    const circle = new google.maps.Circle({
        center: position,
        fillOpacity: 0,
        strokeOpacity: 0,
        map: map,
        radius: radius // radius is in meters
    });
    map.fitBounds(circle.getBounds(), 0);
    circle.setMap(null);
}

function setUserLocation({ lat, lng }) {
    const position = new google.maps.LatLng({ lat, lng });

    let distances = [];
    let resultRadius = 5000; // default 5 km if there are markers
    let anyMarkersWithinRadius = false;

    for (const branchID in branchesData) {
        const branch = branchesData[branchID];

        const distance = google.maps.geometry.spherical.computeDistanceBetween(position, branch.googleMarkerObject.position); // in meters
        distances.push({ branchID: branchID, distance: distance });

        if (distance < 5000) {
            anyMarkersWithinRadius = true;
        }

        const accordionButton = branch.listElement.querySelector('.tps-locations-main-list-branches-item-accordion-button');
        const accordionBody = branch.listElement.querySelector('.tps-locations-main-list-branches-item-accordion-body');

        branch.listElement.classList.remove('active');
        if (accordionButton && accordionBody) {
            accordionButton.setAttribute('aria-expanded', 'false');
            accordionButton.classList.remove('js-is-active');
            accordionBody.classList.remove('js-is-visible');
        }

        branch.googleMarkerObject.setIcon(branch.icon);
    }

    distances.sort((a, b) => a.distance - b.distance);

    if (!anyMarkersWithinRadius) {
        resultRadius = distances[0].distance;
    }

    // Sort branch list items
    distances.forEach((distance, index) => {
        branchesData[distance.branchID].listElement.style.order = index + 1;
    });

    userMarker = new google.maps.Marker({
        position: position,
        map
    });

    zoomIntoLocation(resultRadius, { lat, lng });
}

function searchByInput(e) {
    e.preventDefault();

    setSearchMessage(false);

    const formData = new FormData(e.target);
    const entries = formData.entries();
    const data = Object.fromEntries(entries);

    if (data.location && data.location !== '') {
        const query = data.location.split(' ').join('+');
        if (userMarker) userMarker.setMap(null);

        geocoder.geocode({ address: query }, (results, status) => {
            if (status === 'OK') {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();

                setUserLocation({ lat, lng });

                // scroll list to the top
                branchList.scroll(0, 0);
            } else {
                console.log(`Geocode was not successful for the following reason: ${status}`);
                setSearchMessage(true, "Please enter a valid address or postal code.");
            }
        });
    }
}

function getCurrentLocation(e) {
    e.preventDefault();

    setSearchMessage(false);

    if (userMarker) userMarker.setMap(null);

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            setUserLocation({ lat, lng });

            // scroll list to the top
            branchList.scroll(0, 0);
        });
    } else {
        setSearchMessage(true, "Geolocation is not available. Please enable geolocation for this website.");
    }
}

function setSearchMessage(isShown, msg = "") {
    locationsSearchMessages.forEach(div => {
        div.innerHTML = msg;

        if (isShown) {
            div.classList.remove('hide');
            branchList.classList.add('message-shown');
        } else {
            div.classList.add('hide');
            branchList.classList.remove('message-shown');
        }
    });
}
