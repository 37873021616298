export default class TPSReadingLists {

    constructor() {
        this.readingLists = document.querySelectorAll('.tps-reading-lists');
    }

    init() {
        const _self = TPS.ReadingLists;

        if (_self.readingLists) {
            _self.readingLists.forEach(list => {
                _self.handleTabs(list);
                _self.handleDropdownTabs(list);
            });
            
        }
    }

    handleDropdownTabs(_currentList) {
        const _self = TPS.ReadingLists;
        const _dropdownTab = _currentList.querySelector('.tps-reading-lists-tabs-dropdown');

        if (_dropdownTab) {
            const _dropdownTabList = _dropdownTab.nextElementSibling;
            const _dropdownTabListItems = _dropdownTabList.querySelectorAll('.tps-reading-lists-tabs-item');

            if (_dropdownTabList && _dropdownTabListItems) {
                _dropdownTab.addEventListener('click', (e) => {
                    e.preventDefault();
                    _self.toggleDropdownTabs(_dropdownTab, _dropdownTabList);
                });

                _dropdownTab.addEventListener('keyup', (e) => {
                    e.preventDefault();

                    if (event.code === 'Enter' || event.code === 'Space') {
                        _self.toggleDropdownTabs(_dropdownTab, _dropdownTabList);
                    }
                });

                _dropdownTabListItems.forEach(item => {
                    item.addEventListener('click', (e) => {
                        e.preventDefault();
                        _self.toggleDropdownTabs(_dropdownTab, _dropdownTabList, e.target.innerText);
                    });
                });
            }
        }
    }

    toggleDropdownTabs(_dropdownTab, _dropdownTabList, _activeTabLabel = '') {
        _dropdownTab.classList.toggle("active");
        _dropdownTabList.classList.toggle("active");

        if (_activeTabLabel) {
            _dropdownTab.innerText = _activeTabLabel;
        }
    }

    handleTabs(_currentList) {
        const _self = TPS.ReadingLists;
        const _tabItems = _currentList.querySelectorAll('.tps-reading-lists-tabs-item');
        const _tabContainers = _currentList.querySelectorAll('.tps-reading-lists-tabs-container');;
        
        if (_tabItems && _tabContainers) {
            _tabItems.forEach((tabItem) => {
                tabItem.addEventListener('click', (e) => {
                    e.preventDefault();
                    const targetTabID = e.target.dataset.tabId;

                    _tabItems.forEach((item) => {
                        item.classList.remove('active');
                    });

                    _tabContainers.forEach((tabContainer) => {
                        tabContainer.classList.remove('active');
                    });

                    e.target.classList.add('active');
                    document.getElementById(targetTabID).classList.add('active');
                });
            });
        }
    }
}
