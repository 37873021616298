// This file is only here as an Entry for Webpack.
// Only import the JS files and any scss files that you need webpack to find
// import '../styles/main.scss';

// Helper Functions
import './dom';
import './slidetoggle';
import './accordions';
import './jump-link';
// Module for Display Sticky Fallback
import './header';
// Module for showing/hiding notification bar
import './notification';
// Module for Carousels
import './carousel';
// Modules for Filtering
import './news';
import './view-more';
// Module for Google Map
import './map';
// Module for Forms
import './validation';
import './payment-fine-form';
import './multi-step-form';
import './form-restrictions';
import './request-a-title';
import './my-profile';
// Module for Languages;
import './languages';
// Module for Modals
import './modals';
// Homepage Banner
import './homeBanner';

/** TPS Functions 
 ========================== */

import TPS from './_tps';
// BLK base class
window.TPS = new TPS();
// On DOM ready
window.TPS.onDomReady();
