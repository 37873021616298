// import velocity from 'velocity-animate';
import { ready, getElements, getElement } from './dom';
import { initValidation, validate } from './validation';

const registerForm = document.getElementById('form-register');

// Variables for controlling the form flow
const multiStepForm = getElement('section.multi-step-form');
const progressBar = getElement('[role="progressbar"]');
// const bodyEl = getElement('body');
// const progressBells = getElements('li', progressBar);
const btns = getElements('button[data-step]');
const steps = getElements('div.form-step');

// Variables for validating the form
function scrollToEl(el) {
  const top = el.getBoundingClientRect().top - 50 + window.scrollY;
  window.scroll({
    top,
    behavior: 'smooth',
  });
}

const getStep = el => (el.dataset ? el.dataset.step : el.getAttribute('data-step'));

const getValue = selector => {
  const el = document.querySelector(selector);
  return el && el.value ? el.value : null;
};

function checkNextStep(e) {
  e.preventDefault();
  const clicked = this.nodeType ? this : e.target;
  const currentStep = getElement(`div.form-step.js-is-active`).id;
  const nextStep = getStep(clicked);
  determineSteps(currentStep, nextStep);
}

function determineSteps(currentStep, nextStep) {
  let shouldValidate = true;
  if (
    (currentStep === 'step-two' && nextStep === 'step-preset') ||
    (currentStep === 'step-two-a' && nextStep === 'step-preset') ||
    (currentStep === 'step-three' && nextStep === 'step-two') ||
    (currentStep === 'step-four' && nextStep === 'step-three') ||
    currentStep === 'step-five'
  ) {
    shouldValidate = false;
  }
  if (shouldValidate) validateForm(nextStep);
  else changeStep(nextStep);
}

const hide = () => {
  const currentStep = getElement(`div.form-step.js-is-active`);
  currentStep.classList.remove('js-is-active');
  currentStep.classList.add('js-is-hidden');
};

const show = nextStep => {
  let stepId = nextStep;
  if (nextStep === 'step-two') {
    const location = getValue('input[id="register-location-oakville"]:checked');
    stepId = location === 'register-location-oakville' ? 'step-two-a' : 'step-two-b';
  }
  const stepToShow = getElement(`div.form-step#${stepId}`);
  stepToShow.classList.remove('js-is-hidden');
  stepToShow.classList.add('js-is-active');
};

const changeProgress = nextStep => {
  const active = getElement('span.js-is-active', progressBar);
  const newProgess = getElement(`span.${nextStep}`);
  newProgess.classList.add('js-is-active');
  active.classList.remove('js-is-active');
};

/* eslint no-unused-vars: "warn" */
function changeStep(nextStep) {
  hide();
  show(nextStep);
  changeProgress(nextStep);
    scrollToEl(progressBar);

    // if it's a get a card form
    if (registerForm) {
        saveFormData(nextStep);
    }
}

const startSteps = () => {
    steps.forEach(step => {
        if (!step.classList.contains('step-preset')) step.classList.add('js-is-hidden');
        else step.classList.add('js-is-active');
    });

    // if it's a get a card form
    if (registerForm) {
        restoreFormState();
    }
};

const validateForm = nextStep => {
    const currentStep = getElement(`div.form-step.js-is-active`);
    const inputsToValidate = getElements('[data-validate]', currentStep);

    initValidation(inputsToValidate);

    let isValid = validate(inputsToValidate);

    if (registerForm && nextStep === "step-three" && !tps_hadValidPostal) { // this script is also used by other forms, so check if it's reg
        isValid = false;
    }

    if (isValid) {
        changeStep(nextStep);
    }
};

/**
 * Get phone field element, format field based on user input.
 */
const phoneField = getElement('#register-phone');
let phoneValueLength = 0;
let output;

const autoFormatPhone = () => {
  const phoneValue = phoneField.value;
  const phoneNumbers = phoneValue.replace(/[^0-9]/g, '');
  const area = phoneNumbers.substr(0, 3);
  const pre = phoneNumbers.substr(3, 3);
  const tel = phoneNumbers.substr(6, 4);

  if (area.length < 3) {
    output = `${area}`;
  } else if (area.length === 3 && pre.length < 3) {
    output = `${area}-${pre}`;
  } else if (area.length === 3 && pre.length === 3) {
    output = `${area}-${pre}-${tel}`;
  }

  /**
   * if deleting characters do not format field
   */
  if (phoneValue.length >= phoneValueLength) {
    phoneField.value = output;
    phoneValueLength = output.length;
  } else {
    phoneValueLength = phoneValue.length;
  }
};

function saveFormData(step) {
    let data = {};
    const registerFormData = new FormData(registerForm);
    registerFormData.forEach((value, key) => data[key] = value);
    sessionStorage.setItem('OPLGetACardFormCurrentStep', step);
    sessionStorage.setItem('OPLGetACardForm', JSON.stringify(data));
}

function restoreFormState() {
    if (sessionStorage.getItem('OPLGetACardFormCurrentStep') && sessionStorage.getItem('OPLGetACardForm')) {
        let values = JSON.parse(sessionStorage.getItem('OPLGetACardForm'));
        let step = sessionStorage.getItem('OPLGetACardFormCurrentStep');

        for (let key in values) {
            const input = registerForm.elements[key];
            const val = values[key];

            if (!input) continue;

            if (input.type) {
                switch (input.type) {
                    case 'checkbox':
                        input.checked = !!val;
                        break;
                    default:
                        input.value = val;
                        break;
                }

                if (input.classList.contains('check-postal-code')) {
                    tps_checkPostalCode(input);
                }
            } else {
                if (input[0].type == 'radio') {
                    input.forEach(inp => {
                        if (inp.value == val) {
                            inp.checked = true;
                        }
                    })
                }
            }
        }

        if (values.RegisterLocation === "register-location-oakville") {
            $("#register-workplace").removeAttr("required");
            $('#register-postal-code-a').attr("required", "required");
            $('#register-postal-code-b').removeAttr("required");
        } else {
            $("#register-workplace").attr("required", "required");
            $('#register-postal-code-a').removeAttr("required");
            $('#register-postal-code-b').attr("required", "required");
        } 

        changeStep(step);
    }
}

const main = () => {
    startSteps();
    btns.forEach(btn => btn.addEventListener('click', checkNextStep));
    if (phoneField) {
        phoneField.addEventListener('keyup', autoFormatPhone);
    }
    if (registerForm) {
        registerForm.addEventListener('submit', function (e) {
            saveFormData('step-four');

            if (grecaptcha.getResponse() === '') {
                e.preventDefault();
                alert('Please check the recaptcha.');
                return false;
            }

            return true;
        });
    }
};

if (multiStepForm) ready(main);
// The purpose of this module is control the multi-step form process
// Only one form step should be shown at a time. It should always start on the first step
// The progress bar must align with the active step
// Pressing the next / previous buttons allows the user to go forward or backward in the step process
