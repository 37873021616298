export default class TPSNav {
    constructor() {
        this.desktopNavItems = null;
        this.headerDropdowns = null;
    }

    init() {
        this.initDesktopNavItems();
        this.initHeaderDropdowns();
    }

    initDesktopNavItems() {
        const self = this;

        self.desktopNavItems = document.querySelectorAll('.nav-desktop-list-item-link.has-submenu');

        self.desktopNavItems.forEach(item => {
            const targetSubmenuID = item.getAttribute('aria-controls');
            const submenu = item.parentElement.querySelector(`#${targetSubmenuID}`);
            item._submenu = submenu;

            // open-close submenu (click event is also triggered on enter/spacebar by default)
            item.addEventListener('click', function () {
                self.toggleSubmenu(item);
            });

            // close on focusout from submenu items
            submenu.addEventListener('focusout', function (e) {
                const currentFocused = e.relatedTarget;
                if (!submenu.contains(currentFocused)) {
                    self.closeSubmenu(item);
                }
            });
        });
    }

    initHeaderDropdowns() {
        const self = this;

        self.headerDropdowns = document.querySelectorAll('[data-header-dropdown]');
        self.headerDropdowns.forEach(dropdown => {
            const name = dropdown.dataset.headerDropdown;
            const button = document.querySelector(`[data-header-dropdown-button="${name}"]`);
            const closeButton = document.querySelector(`[data-header-dropdown-close="${name}"]`);

            dropdown._button = button;
            dropdown._closeButton = closeButton;

            // toggle button click/keypress
            if (button) {
                button.addEventListener('click', function (e) {
                    self.toggleHeaderDropdown(dropdown);
                });
            }

            // close button
            if (closeButton) {
                closeButton.addEventListener('click', function (e) {
                    self.closeHeaderDropdown(dropdown);
                });
            }
        });
    }

    toggleSubmenu(button) {
        const self = this;

        if (button.getAttribute('aria-expanded') === 'true') {
            self.closeSubmenu(button);
        } else {
            self.openSubmenu(button);
        }
    }

    openSubmenu(button) {
        const self = this;

        // close others, open this button
        self.desktopNavItems.forEach(item => {
            if (item === button) {
                button.setAttribute('aria-expanded', 'true');
                button._submenu.classList.add('visible');
            } else {
                self.closeSubmenu(item);
            }
        });
    }

    closeSubmenu(button) {
        button.setAttribute('aria-expanded', 'false');
        button._submenu.classList.remove('visible');
    }

    toggleHeaderDropdown(dropdown) {
        const self = this;

        if (dropdown._button.getAttribute('aria-expanded') === 'true') {
            self.closeHeaderDropdown(dropdown);
        } else {
            self.openHeaderDropdown(dropdown);
        }
    }

    openHeaderDropdown(dropdown) {
        const self = this;

        // close others, open this dropdown
        self.headerDropdowns.forEach(item => {
            if (item === dropdown) {
                dropdown._button.setAttribute('aria-expanded', 'true');
                dropdown.style.display = 'block';

                // special treatment for nav!
                if (dropdown.dataset.headerDropdown === 'nav') {
                    // body unscrollable
                    document.body.style.overflowY = 'hidden';
                    // trap focus
                    window.TPS.Utilities.trapFocus(document.querySelector('header'));
                }
            } else {
                self.closeHeaderDropdown(item);
            }
        });
    }

    closeHeaderDropdown(dropdown) {
        dropdown._button.setAttribute('aria-expanded', 'false');
        dropdown.style.display = '';

        // special treatment for nav!
        if (dropdown.dataset.headerDropdown === 'nav') {
            // body is back to scrollable
            document.body.style.overflowY = '';
            window.TPS.Utilities.releaseTrapFocus();
        }
    }
}
