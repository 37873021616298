
import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/keyboardnav';

export default class TPSUtilities {

    constructor() {

        this.window = $(window);
        this.document = $(document);
        this.focusUtilIgnoreUntilFocusChanges = false;
        this.trappedFocusElement = null;
    }

    init() {
        const _self = TPS.Utilities;

        // check if there is a page
        // anchor in the URL on load
        if (location.hash) {

            // we are going to setup an interval
            // to check if the webfonts are loaded
            // b/c they will effect the scroll height
            // of elements on the page after load
            const intervalCheck = setInterval(() => {

                if (document.fonts.status === 'loaded') {
                    // the fonts are loaded so
                    // we can stop the interval
                    clearInterval(intervalCheck);
                    // now we will check if
                    // there is a page anchor in the URL and scroll to it
                    const _hash = location.hash;
                    const __hashTarget = document.querySelector(_hash);
                    if (__hashTarget) {
                        const _topScroll = _self.getScrollToPosition(__hashTarget);
                        _self.smoothScrollTo(_topScroll, 500);
                    }



                }
            }, 100);

            
        }

    }

    getScrollToPosition(_target) {
        const _self = TPS.Utilities;

        const _header = document.querySelector('#header');

        const _headerHeight = _header.offsetHeight;
        const _topScroll = _target.getBoundingClientRect().top + window.scrollY - _headerHeight;

        return _topScroll;
    }

    smoothScrollOnClick(e) {
        const _self = TPS.Utilities;
        const _elem = e.target;
        const _elemHref = _elem.getAttribute('href');
        const _target = document.querySelector(_elemHref);

        const _topScroll = _self.getScrollToPosition(_target);

        _self.smoothScrollTo(_topScroll, 500);

    }

    // Polyfilled smooth scrolling for IE, Edge & Safari
    smoothScrollTo(to, duration) {
        const element = document.scrollingElement || document.documentElementl;
        const start = element.scrollTop;
        const change = to - start;
        const startDate = +new Date();

        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };

        const animateScroll = _ => {
            const currentDate = +new Date();
            const currentTime = currentDate - startDate;
            element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));

            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            } else {
                element.scrollTop = to;
            }
        };

        animateScroll();
    }
    
    updateUrlParameter(uri, key, value) {
        // remove the hash part before operating on the uri
        var i = uri.indexOf('#');
        var hash = i === -1 ? '' : uri.substr(i);
        uri = i === -1 ? uri : uri.substr(0, i);

        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";

        if (!value) {
            // remove key-value pair if value is empty
            uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
            if (uri.slice(-1) === '?') {
                uri = uri.slice(0, -1);
            }
            // replace first occurrence of & by ? if no ? is present
            if (uri.indexOf('?') === -1) {
                uri = uri.replace(/&/, '?');
            }
        } else if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            uri = uri + separator + key + "=" + value;
        }
        return uri + hash;
    }

    addDatePickerToInput(_selector) {
        if (!_selector)
            return;


        const _self = TPS.Utilities;
        const _input = document.querySelector(_selector);

        const _litepicker = _self.initLitePicker(_input);

        console.log(_selector);
        console.log(_input);


    }

    initLitePicker(_elem) {

        const _parent = _elem.parentElement;
        _parent.classList.add('tps-calendar-wrapper');

        return new Litepicker({
            element: _elem,
            parentEl: _parent,
            format: 'DD/MM/YYYY',
            plugins: ['keyboardnav'],
            autoRefresh: true,
            autoApply: true,
            firstDay: 0,
            position: 'bottom',
            zIndex: 999
        });

    }

    isFocusable(element) {
        if (element.tabIndex < 0) {
            return false;
        }

        if (element.disabled) {
            return false;
        }

        switch (element.nodeName) {
            case 'A':
                return !!element.href && element.rel != 'ignore';
            case 'INPUT':
                return element.type != 'hidden';
            case 'BUTTON':
            case 'SELECT':
            case 'TEXTAREA':
                return true;
            default:
                return false;
        }
    }

    /**
     * Set focus on descendant nodes until the first focusable element is found.
     * @param {any} element DOM node for which to find the first focusable descendant.
     * @returns {boolean} true if a focusable element is found and focus is set.
     */
    focusFirstDescendant(element) {
        for (var i = 0; i < element.childNodes.length; i++) {
            var child = element.childNodes[i];
            if (
                window.TPS.Utilities.attemptFocus(child) ||
                window.TPS.Utilities.focusFirstDescendant(child)
            ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Find the last descendant node that is focusable.
     * @param element DOM node for which to find the last focusable descendant.
     * @returns {boolean} true if a focusable element is found and focus is set.
     */
    focusLastDescendant(element) {
        for (var i = element.childNodes.length - 1; i >= 0; i--) {
            var child = element.childNodes[i];
            if (
                window.TPS.Utilities.attemptFocus(child) ||
                window.TPS.Utilities.focusLastDescendant(child)
            ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Set Attempt to set focus on the current node.
     * @param {any} element The node to attempt to focus on.
     * @returns {boolean} true if element is focused.
     */
    attemptFocus(element) {
        if (!window.TPS.Utilities.isFocusable(element)) {
            return false;
        }

        window.TPS.Utilities.focusUtilIgnoreUntilFocusChanges = true;
        try {
            element.focus();
        } catch (e) {
            // continue regardless of error
        }
        window.TPS.Utilities.focusUtilIgnoreUntilFocusChanges = false;
        return document.activeElement === element;
    }

    /**
     * Handler function for trapping focus
     * @param {any} event Event passed from the 'focus' event
     */
    trapFocusHandler(event) {
        if (window.TPS.Utilities.focusUtilIgnoreUntilFocusChanges) {
            return;
        }
        const element = window.TPS.Utilities.trappedFocusElement;

        if (element.contains(event.target)) {
            element._lastFocus = event.target;
        } else {
            window.TPS.Utilities.focusFirstDescendant(element);
            if (element._lastFocus == document.activeElement) {
                window.TPS.Utilities.focusLastDescendant(element);
            }
            element._lastFocus = document.activeElement;
        }
    }

    /**
     * Trap focus on set element. Don't forget to release the trap. 
     * @param {any} element
     */
    trapFocus(element) {
        window.TPS.Utilities.trappedFocusElement = element;
        document.addEventListener('focus', window.TPS.Utilities.trapFocusHandler, true);
    }

    /**
     * Release trap focus
     */
    releaseTrapFocus() {
        window.TPS.Utilities.trappedFocusElement = null;
        window.TPS.Utilities.focusUtilIgnoreUntilFocusChanges = false;
        document.removeEventListener('focus', window.TPS.Utilities.trapFocusHandler, true);
    }
}
