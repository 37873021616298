// returns true if the input is NOT REQUIRED
// returns true if the input has a value & IS REQUIRED
// returns false if the input has NO value & IS REQUIRED
const validateInput = el => !el.validity.valueMissing;

// returns true if the input values matches the required type
// returns false if the input value does not match the required type
const validateType = el => !el.validity.typeMismatch;

// returns true if the input passes both the min & max constraints
// returns false if the char count is too little or too much
const validateLength = el => validateMinLength(el) && validateMaxLength(el);

// returns true if the input contains a value, and the length of that value meets the maxLength
// returns true if the input is empty but is NOT REQUIRED
// returns false if the input contains a value, but the value does NOT meet the maxLength
// returns false if the input is empty and IS REQUIRED
const validateMaxLength = el => (el.value.length !== 0 ? !el.validity.tooLong : validateInput(el));

// returns true if the input contains a value, and the length of that value meets the minLength
// returns true if the input is empty but is NOT REQUIRED
// returns false if the input contains a value, but the value does NOT meet the minLength
// returns false if the input is empty and IS REQUIRED
const validateMinLength = el => (el.value.length !== 0 ? !el.validity.tooShort : validateInput(el));

// returns true if the input matches the pattern
// returns false if the input does not match the pattern
const validatePattern = el => !el.validity.patternMismatch;

// returns true if the input contains valid chars
// returns false if the input contains chars that cannot be validated
const validateChars = el => !el.validity.badInput;

// returns true if all constraints match
const isValid = el => el.validity.valid;

// returns true if all constraints match
// else fires an invalid event
const checkValidationState = el => el.checkValidity();

// takes an input and msg and creates a custom error msg to be displayed when an input fails to validate
const setCustomError = (el, msg) => el.setCustomValidity(msg);

// Takes an array of inputs to check validation states
// returns true if all the inputs meet the their validation state
// returns false if any one of the inputs is invalid
// const validate = els => Array.from(els, el => el.reportValidity());
const validate = els => Array.from(els).every(el => el.reportValidity());

// setups up an eventlistener on an element to listen for validation checks
const initValidation = els => {
  els.forEach(el => {
    el.addEventListener('invalid', e => console.log(e, 'invalid'));
  });
};

// This module is using the constraint API to validate form inputs
// This API is supported in IE 10 & 11, but has no support for the reportValidity & tooShort methods
// This API has NO opera mini support

// if the el is required end empty

export {
  validateInput,
  validateType,
  validateLength,
  validateMaxLength,
  validateMinLength,
  validatePattern,
  validateChars,
  checkValidationState,
  isValid,
  setCustomError,
  initValidation,
  validate,
};
