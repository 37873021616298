export default class TPSMasthead {

    constructor() {

        this.mastheads = document.querySelectorAll('[data-masthead-mobile-image]');

    }

    init() {

        const _this = TPS.Masthead;

        if (_this.mastheads.length > 0) {
            _this.setupListeners();
        }
        // 

    }

    setupListeners() {

        const _this = TPS.Masthead;

        // change the background image depending on the screen size
        window.addEventListener("resize", e => {

            const _win = document.querySelector('html');

            [..._this.mastheads].forEach(masthead => {

                let img = masthead.dataset.mastheadImage;
                
                if (_win.clientWidth < 768) {
                    img = masthead.dataset.mastheadMobileImage;
                }
                const style = `url('${img}')`;

                if (style != masthead.style.backgroundImage) {
                    masthead.style.backgroundImage = `url('${img}')`;
                }                

            });

        });

    }
}
