function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

function loaded(fn) {
  if (document.readyState === 'complete') {
    fn();
  } else {
    document.addEventListener('load', fn);
  }
}

function verifyKey(e) {
  if (e.which === 32 || e.which === 13) {
    e.preventDefault();
    this.click();
  }
}

// Convert an iterable object into an array. Typically used for NodeLists.
const makeArray = iterable => {
  return Array.prototype.slice.call(iterable);
};

// Get a single element via a selector. Parent context optional (defaults to document).
const getElement = (selector, context) => {
  return (context || document).querySelector(selector);
};

// Get an array of elements via a selector. Parent context optional (defaults to document).
const getElements = (selector, context) => {
  return makeArray((context || document).querySelectorAll(selector));
};

// Get an array of children for an element.
const getChildren = (element) => {
  return makeArray(element.children);
};

// Empty an element of all contents.
const empty = (element) => {
  while (element.firstChild) {
    element.removeChild(element.firstChild); 
  }
  return element;
};

// Empty an element and then fill it with children.
const fill = (element, ...children) => {
  empty(element);
  append(element, ...children);
  return element;
};

// Append nodes to a parent element.
const append = (element, ...children) => {
  children.forEach(child => element.appendChild(child));
  return children;
};

// Prepend nodes to a parent element.
const prepend = (element, ...children) => {
  children.forEach(child => element.insertBefore(child, element.firstChild));
  return children;
};

export {
  getElement,
  getElements,
  getChildren,
  empty,
  fill,
  append,
  prepend,
  ready,
  loaded,
  verifyKey
};
