import { verifyKey } from './dom';

// This module is responsible for adding additional resources into the resource list whenever a view more button is clicked
// This includes News articles, Events, and Library Resources
class ElementLoader {
  constructor(els, btn, pageCount) {
    this.els = els;
    this.btn = btn;
    this.pageCount = pageCount;
    this.maxCount = els.length;
    this.page = 0;
    this.onLoad();
  }

  increment = () => {
    this.page += 1;
  };

  shouldBtnShow = () => {
    const { maxCount, pageCount, page } = this;
    if (maxCount <= pageCount * page) {
      hideBtn(this.btn);
    }
  };

  showAndHide = () => {
    const { els, page, pageCount } = this;
    const show = Array.from(els.slice(0, pageCount * page));
    const hide = els.filter(el => !show.includes(el));
    showEls(show);
    hideEls(hide);
  };

  showMore = e => {
    if (e) e.preventDefault();
    const disabled = this.btn.getAttribute('disabled');
    if (disabled) return;
    this.increment();
    this.showAndHide();
    this.shouldBtnShow();
  };

  onLoad = () => {
    this.btn.addEventListener('click', this.showMore);
    this.btn.addEventListener('keydown', verifyKey);
    this.showMore();
  };
}

// These are the functions that are for the events and resouces filters as they are using isotope

let currentPage = 0;

const increment = () => {
  currentPage += 1;
};

const reset = (arr, btn, count) => {
  currentPage = 0;
  showMore(arr, btn, count);
};

const hideEls = arr =>
  arr.forEach(el => {
    el.classList.add('js-is-hidden');
  });

const showEls = arr =>
  arr.forEach(el => {
    el.classList.remove('js-is-hidden');
  });

const hideBtn = btn => {
  btn.setAttribute('disabled', true);
};

const showBtn = btn => {
  btn.setAttribute('disabled', false);
};

const checkBtn = (btn, count, limit) => {
  if (limit <= count * currentPage) {
    hideBtn(btn);
  } else {
    showBtn(btn);
  }
};

const showAndHide = (arr, count) => {
  const show = Array.from(arr.slice(0, count * currentPage).map(el => el.element));
  const hide = arr.filter(el => !show.includes(el.element)).map(el => el.element);
  showEls(show);
  hideEls(hide);
};

const showMore = (arr, btn, count) => {
  increment();
  showAndHide(arr, count);
  checkBtn(btn, count, arr.length);
};

export { showMore, reset, ElementLoader };

// We need an array of items (filteredItems)
// We need a viewMore btn (view more btn)
// We need a pageCount (number of els to show on each page)

// ! On filter hide up to the cap
// const setFilterCap = () => {};
