
export default class TPSOffCanvas {

    constructor() {

        this.window = $(window);
        this.document = $(document);
    }

    toggleOffCanvas() {

        const _self = TPS.OffCanvas;
        const _body = $('body');

        if (_body.hasClass('tps-off-canvas-active')) {
            _body.removeClass('tps-off-canvas-active');
        } else {
            _body.addClass('tps-off-canvas-active');
        }


    }

}
