
export default class TPSResourcesSearch {

    constructor() {

        this.window = $(window);
        this.document = $(document);
        this.searchSelector = '';
        this.baseFilter = '';
        this.searchTerm = '';
        this.props = {
            urlBase: '/'
        };
    }

    // init() {}

    load(_selector, _opts) {
        const _self = TPS.ResourcesSearch;

        _self.props = _opts;
        _self.baseSearchFilter = _self.props.searchParams.filter;
        _self.searchSelector = _selector;

        const _form = document.getElementById('opl-online-resources-search');

        _self.setSearchData(_form);

        TPS.AzureSearch.getSearchResults(_self.searchSelector, _self.props);
    }

    submitSearch(e) {
        const _self = TPS.ResourcesSearch;
        
        // prevent the page refresh on submit
        e.preventDefault();

        _self.setSearchData(e.target);

        TPS.AzureSearch.getSearchResults(_self.searchSelector, _self.props);

    }

    resetSearch(e) {
        const _self = TPS.ResourcesSearch;
        const _form = e.target;
        // prevent default action so fields can be cleared manually
        e.preventDefault();
        
        // reset the fields manually
        $(_form.elements).each(function (_k, _v) {

            if (_v.type === 'radio' || _v.type === 'checkbox') {
                _v['checked'] = false;
            } else if (_v.type !== 'reset' && _v.type !== 'submit') {
                _v.value = '';
            }

        });

        // set the all category to selected
        _form.elements['resource-filter-all'].checked = true;
        // set the search params
        _self.setSearchData(e.target);
        // get the results from the index
        TPS.AzureSearch.getSearchResults(_self.searchSelector, _self.props);
    }

    setSearchData(_form) {
        const _self = TPS.ResourcesSearch;

        // get the form data
        const _data = new FormData(_form);

        _self.props.searchParams.filter = _self.baseSearchFilter;
        _self.props.searchParams.skip = 0;

        for (let field of _data.entries()) {
            if (field[0] === 'searchterm') {
                _self.searchTerm = field[1];
                _self.props.searchParams.search = field[1];
            }
            else {
                _self.props.searchParams.filter = _self.setSearchFilter(field);
            }
        }

    }

    setSearchFilter(_filter) {
        const _self = TPS.ResourcesSearch;
        let _filterText = _self.baseSearchFilter;
        let _filterVal = '';

        if (Array.isArray(_filter) && _filter.length > 0 && _filter[1] !== '') {
            _filterText += ` and (${_filter[0]}/any(m: m eq '${_filter[1]}'))`;
            _filterVal = _filter[1];
        }


        _self.updateFilterHistory(_filterVal);

        return _filterText; 
    }

    updateFilterHistory(_filterValue) {

        const _currentPath = window.location.pathname;
        const _currentPathParts = _currentPath.split('/');
        let _newPathParts = [];
        let _newPath = '/';

        // lets check for empty slots in the array,
        // for example if the last character in the path is a slash`/`
        _currentPathParts.forEach((item) => {

            if (typeof item !== 'undefined' && item !== '' && item !== null) {
                _newPathParts.push(item);
            }
        });

        // if the path has more than one item
        // lets remove the last one which would
        // be the active filter
        if (_newPathParts.length > 1) {
            _newPathParts.pop();
        }
        // add the new filter value
        // as the last item if it exists
        if (typeof _filterValue !== 'undefined' && _filterValue !== '' && _filterValue !== null) {
            _newPathParts.push(_filterValue);
        }

        _newPath += _newPathParts.join('/') + '/';


        // push the new search query string to the browser history
        window.history.replaceState({ 'url': _newPath }, '', _newPath);  
    }

    changeSearchFilter(e) {
        const _self = TPS.ResourcesSearch;

        $(e.target.form).trigger('submit');
        
    }

    

}
