export default class TPSBackToTop {

    constructor() {

        this.backToTop = document.querySelector('.tps-back-to-top');

    }

    init() {
        const self = TPS.BackToTop;
        
        if (self.backToTop) {
            self.scrollListener();
        }
    }

    scrollListener() {
        const self = TPS.BackToTop;
        
        window.addEventListener('scroll', function () {
            const scrollHeight = document.documentElement.scrollHeight;
            const visibleHeight = window.innerHeight;
            const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);

            if (scrollPosition > (scrollHeight - visibleHeight) / 2) {
                self.backToTop.style.display = 'block';
            } else {
                self.backToTop.style.display = 'none';
            }
        });

        self.backToTop.addEventListener('click', function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    }
}
