import { getElement, getElements } from './dom';

const header = getElement('#header');
const notification = getElement(`[data-for="notification-bar"]`);
const alerts = getElements('.alert-message', notification);
let storage;

// This function makes sure we dont add duplicate ids to the array
function addElementToArray(el, array) {
  if (array.indexOf(el) === -1) array.push(el);
}

// This gets called to show the notification
function showNotification() {
  header.classList.add('js-notification-is-active');
}

// This gets called to hide the notification
// This also sets a value in local storage to check if they have seen that notification or not
function hideNotification() {
  if (!storage) {
    storage = {
      seenAlerts: [],
    };
  }
  alerts.forEach(alert => addElementToArray(alert.getAttribute('data-alert'), storage.seenAlerts));
  localStorage.setItem('notification', JSON.stringify(storage));
  header.classList.remove('js-notification-is-active');
}

// This gets called on Pageload
// We want to check if the user has already seen the notification banner
// Get all alerts in storage (array)
// Get all alerts that are showing (array)
// If all alerts that are showing exist in the storage, they have seen all the alerts. Hide the banner
// If an alert that is showing doesnt exist in the storage, they havent seen all the alerts. Show the banner
function checkNotification() {
  // Notification should show by default
  let shouldNotificationShow = true;
  // Get all alerts that are showing
  const visibleAlerts = alerts.map(alert => alert.getAttribute('data-alert'));
  // Get all alerts that are in storage
  const currentStorage = JSON.parse(localStorage.getItem('notification'));
  // If we are already storing something in localstorage, use it as our storage Obj
  if (currentStorage) {
    storage = currentStorage;
    // Check that all alerts exist in storage already
    // If every visible alert has been seen shouldNotificationShow will be false
    shouldNotificationShow = !visibleAlerts.every(
      alertId => currentStorage.seenAlerts.indexOf(alertId) >= 0,
    );
  }
  if (shouldNotificationShow) {
    showNotification();
  } else {
    hideNotification();
  }
}

export { checkNotification, hideNotification };
