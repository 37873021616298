import { ready } from './dom';

const jumpLink = () => {
  const { hash } = window.location;
  if (!hash) return;
  const target = document.getElementById(hash.replace('#', ''));
  if (target.tagName === 'A') {
    target.click();
    window.setTimeout(() => window.scrollBy(0, -225), 0);
  }
};

ready(jumpLink);
