/* eslint no-unused-vars: "warn" */
/* eslint no-param-reassign: "warn" */
// We are using swiper as the carousel for this project
// Swiper controls the carousels/sliders on the homepage
// import Swiper from 'swiper';
/* global Swiper b:true */

import { ready, getElement, getElements } from './dom';

// Media Query to destroy the carousel at
const mediaQuery = window.matchMedia('(min-width: 1024px)');
// Three types of carousels. Program / Release / Loop
const carouselsToMount = getElements('[data-carousel]');

const defaultConfig = {
  updateOnImagesReady: true,
  loop: false,
  loopedSlides: 0,
  centeredSlides: false,
  slidesPerView: 'auto',
  clickable: false,
  //cssMode: true,
  a11y: true,
  grabCursor: true,
  keyboardControl: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
        slidesPerView: 1
    },
    // when window width is >= 640px
    640: {
        slidesPerView: 2
    },
    // when window width is >= 768px
    1024: {
        slidesPerView: 3
    },
    // when window width is >= 768px
    1280: {
        slidesPerView: 4
    }
  },
  on: {
    imagesReady: function rad () {
      this.slides.forEach(i=> changeImgSrcToDefault(i));
    },
  }
};

const verticalConfig = {
    updateOnImagesReady: true,
    loop: false,
    loopedSlides: 0,
    loopFillGroupBlank: false,
    centeredSlides: false,
    // slidesPerView: 'auto',
    slidesOffsetAfter: 0,
    spaceBetween: 20,
    clickable: false,
    //cssMode: true,
    a11y: true,
    grabCursor: true,
    keyboardControl: true,
    direction: "vertical",
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 4
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 4
        },
        // when window width is >= 768px
        1024: {
            slidesPerView: 4
        },
        // when window width is >= 768px
        1280: {
            slidesPerView: 4
        }
    },
    //on: {
    //    imagesReady: function rad() {
    //        this.slides.forEach(i => changeImgSrcToDefault(i));
    //    },
    //}
    on: {
        init: function (swiper) {
            //const slider = document.querySelector(".process-slider");
            //const currentSlide = swiper.slides[swiper.activeIndex];
            //const currentSlideItem = currentSlide.children[0];
            //slider.style.height = currentSlideItem.clientHeight + "px";
        },
        slideChange: function (swiper) {
            //const slider = document.querySelector(".process-slider");
            //const currentSlide = swiper.slides[swiper.activeIndex];
            //const currentSlideItem = currentSlide.children[0];
            //slider.style.height = currentSlideItem.clientHeight + "px";
        },
        setTranslate: function (swiper, translate) {
            //
        },
        slideChangeTransitionEnd: function () {
            if ($('body').width() < 410) {
                $('.swiper-content-vertical .swiper-wrapper').css({ height: 500 })
            } else {
                $('.swiper-content-vertical .swiper-wrapper').css({ height: 480 })
            }
            this.update();
        }
    }
};

const changeImgSrcToDefault = (imgElement) => {
  const itemFormat = imgElement.getAttribute('data-item-format')
  if(imgElement.naturalWidth < 25){
      const parent = imgElement.parentNode;
      const img = document.createElement('img'); 
      img.setAttribute("data-item-format", itemFormat);
      img.setAttribute("alt", imgElement.alt)
      if(itemFormat === "DVD" || itemFormat === "BLURAY") {                        
          img.src = "/CMS/getmedia/0808ed96-85bf-45a3-9f79-f80c2912ebbc/movie-default.png";
      }
      else if (itemFormat === "MUSIC_CD") {            
          img.src = "/CMS/getmedia/5577df2b-a9f4-4045-b8c0-4f2b9be90250/music-default.png";
      }
      else {
          img.src = "/CMS/getmedia/cbd73ce7-aa90-46ab-8d49-1d86f2c6f0c8/book-default.png";
      }
      parent.insertBefore(img, imgElement);
      parent.removeChild(imgElement);
  }
} 


const loopConfig = {
  updateOnImagesReady: true,
  loop: true,
  //loopedSlides: 1,
  centeredSlides: false,
  slidesPerView: 'auto',
  clickable: false,
  a11y: true,
  grabCursor: true,
  keyboardControl: true,
  //cssMode: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
        slidesPerView: 1
    },
    // when window width is >= 640px
    640: {
        slidesPerView: 2
    },
    // when window width is >= 768px
    768: {
        slidesPerView: 3
    },
    // when window width is >= 1024px
    1024: {
        slidesPerView: 4
    },
    // when window width is >= 1280px
    1280: {
        slidesPerView: 5
    }
  },
  on: {
    imagesReady: function rad (e) {
      for(let i = 0; i < this.slides.length; i+=1){
        const img = getElement('img',this.slides[i]);
        changeImgSrcToDefault(img);
      }
    },
  }
 
};

class OPLSlider {
  constructor(el, config) {
    this.el = el;
    this.config = config;
    this.init();
  }

  init() {
      this.slide();
  }


  setNavigation() {
    this.config.navigation = {
      nextEl: getElement('.swiper-button-next', this.el.parentElement),
      prevEl: getElement('.swiper-button-prev', this.el.parentElement),
    };
  }

  slide() {
    this.setNavigation();
    this.swiper = new Swiper(this.el, this.config);
    if (this.config !== loopConfig) this.swiper.slideTo(2);
  }
}

const returnConfig = type => {
  switch (type) {
    case 'program': {
          return verticalConfig;
    }
    case 'release': {
          return defaultConfig
              ;
    }
    case 'loop': {
      return loopConfig;
    }
    default: {
      return defaultConfig;
    }
  }
};

const main = () => {
  carouselsToMount.forEach(carousel => {
    const typeOf = carousel.dataset
      ? carousel.dataset.carousel
      : carousel.getAttribute('data-carousel');
    return new OPLSlider(carousel, returnConfig(typeOf));
  });
};

if (carouselsToMount.length >= 1) ready(main);
