export default class TPSFederatedSearch {

    constructor() {
        this.window = $(window);
        this.document = $(document);
        this.groups = [];
        this.countsAdded = false;
    }

    // called on dom ready
    init() {
        const _self = TPS.FederatedSearch;
    }
    

    // Handle Groups Click Event
    handleGroupsClickEvent(e, _indexUiKey_targetGroup) {

        if (!_indexUiKey_targetGroup || typeof _indexUiKey_targetGroup !== 'string')
            return;

        const _self = TPS.FederatedSearch;
        const tab_targetGroup = e.currentTarget;
        // is the current group active?
        const isActive = tab_targetGroup.classList.contains('active');

        // if the current group is not active
        // then lets switch the current group
        if (!isActive) {

            _self.changeActiveGroup(_indexUiKey_targetGroup)

        }

        

        
    }

    // handle the groups dropdown change
    handleGroupsChangeEvent(e) {
        const _self = TPS.FederatedSearch;

        if (e.currentTarget.value) {
            _self.changeActiveGroup(e.currentTarget.value);
        }
    }

    // change the current group
    changeActiveGroup(_indexUiKey_targetGroup) {
        const _self = TPS.FederatedSearch;

        // lets get the actual active tab
        const tab_activeGroup = document.querySelector('.tps-federated-search-results-groups-nav-item-cta.active');
        // get the wrapper of the target group
        const resultsWrapper_targetGroup = document.querySelector(`#tps-azure-search-results-group-wrapper-${_indexUiKey_targetGroup}`);
        // get the tab of the target group
        const tab_targetGroup = document.querySelector(`.tps-federated-search-results-groups-nav-item-cta[data-index-ui-key='${_indexUiKey_targetGroup}']`);
        // get the mobile group selector
        const groupSelector = document.querySelector(`#tps-federated-search-results-groups-nav-select`);

        if (tab_activeGroup && tab_targetGroup && resultsWrapper_targetGroup) {

            // get the "index UI Key" from the button's dataset
            const indexUiKey_activeGroup = tab_activeGroup.dataset["indexUiKey"];
            // get the results wrapper container, to show/hide
            const resultsWrapper_activeGroup = document.querySelector(`#tps-azure-search-results-group-wrapper-${indexUiKey_activeGroup}`);
            // get the results listing wrapper container, to clear/re-render the listing
            const resultsList_activeGroup = document.querySelector(`#tps-federated-search-results-${indexUiKey_activeGroup}`);
            // get the results message container, to clear/re-render the message
            const resultsMsgContainer_activeGroup = document.querySelector(`#tps-federated-search-results-msg-${indexUiKey_activeGroup}`);
            // get the results pagination wrapper, to clear/re-render the pagination
            const paginationWrapper_activeGroup = document.querySelector(`#tps-federated-search-results-pager-${indexUiKey_activeGroup}`);
            // get the results sort element, to reset it
            const sortSelect_activeGroup = document.querySelector(`#sort-select-${indexUiKey_activeGroup}`);
            // get the results facaets wrapper, if it exists
            // for both the target and active group
            const facetsWrapper_activeGroup = document.querySelector(`#tps-federated-search-results-facets-${indexUiKey_activeGroup}`);
            const selectedFacetsWrapper_activeGroup = document.querySelector(`#tps-azure-search-active-facets-list-${indexUiKey_activeGroup}`);
            const facetsWrapper_targetGroup = document.querySelector(`#tps-federated-search-results-facets-${_indexUiKey_targetGroup}`);

            // get the 'upcoming' quick filter
            // so that we can trigger the click
            // and reset the date filters
            const upcomingCTA_activeGroup = document.querySelector(`#tps-date-filter-today-${indexUiKey_activeGroup}`)

            // if the "active" group container exists
            if (resultsWrapper_activeGroup) {

                // FIRST, Hide the active group results container
                resultsWrapper_activeGroup.classList.remove('active');
                tab_activeGroup.classList.remove('active');
                // if the facet wrapper exists, hide it
                if (facetsWrapper_activeGroup) {
                    facetsWrapper_activeGroup.classList.remove('active');
                    if (upcomingCTA_activeGroup) {
                        TPS.AzureSearch.triggerDateChange = false;
                        TPS.AzureSearch.setDate(`#tps-date-filter-upcoming-${indexUiKey_activeGroup}`, 'upcoming');
                        TPS.AzureSearch.triggerDateChange = true;
                    }
                }

                // if there is no search term,
                // we dont need to clear the results

                if (TPS.AzureSearch.searchTerm !== '' && TPS.AzureSearch.searchTerm !== null && typeof TPS.AzureSearch.searchTerm !== 'undefined') {

                    // reset the active group the sort dropdown
                    sortSelect_activeGroup.selectedIndex = 0;

                    // THEN, we will reset the content inside
                    // empty the active group results container
                    if (resultsWrapper_activeGroup) {
                        resultsList_activeGroup.innerHTML = "";
                    }
                    // empty the active group the pagination container
                    if (paginationWrapper_activeGroup) {
                        paginationWrapper_activeGroup.innerHTML = "";
                    }
                    // empty the active group the pagination container
                    if (resultsMsgContainer_activeGroup) {
                        resultsMsgContainer_activeGroup.innerHTML = "";
                    }
                    // empty the selected facets container
                    if (selectedFacetsWrapper_activeGroup) {
                        selectedFacetsWrapper_activeGroup.innerHTML = "";
                    }

                }

                // THEN, we will "activate" the selected tab
                resultsWrapper_targetGroup.classList.add('active');
                tab_targetGroup.classList.add('active');
                /// if the facet wrapper exists, show it
                if (facetsWrapper_targetGroup) {
                    facetsWrapper_targetGroup.classList.add('active');
                }

                if (groupSelector) {
                    groupSelector.value = _indexUiKey_targetGroup;
                }

                // check if we have a search term
                if (TPS.AzureSearch.searchTerm !== '' && TPS.AzureSearch.searchTerm !== null && typeof TPS.AzureSearch.searchTerm !== 'undefined') {
                    // And After we will fetch the search results for the selected tab
                    _self.getSearchResults(_indexUiKey_targetGroup);
                }
            }
        }
    }

    // get the search results for each group
    getSearchResults(_indexUiKey) {

        if (!_indexUiKey)
            return;

        const _self = TPS.FederatedSearch;

        const baseProps = {
            facetsSelected: [],
            dates: {
                hasDates: false
            }
        };
        const searchProps = window[`OplSearchOptions_${_indexUiKey}`];

        // cancel the function if searchprops
        // doesnt exist, or is not an object
        if (!searchProps || typeof searchProps !== 'object')
            return;

        // create a new instance of the searchprops,
        // so as the objects are passed around as variables
        // they don't get mixed up
        const mergedProps = Object.assign({}, baseProps, searchProps);

        if (mergedProps && typeof mergedProps === 'object') {

            // now we will get the page size, b.c it could be different
            // depending on the devince (mobile vs. desktop)
            mergedProps.results.pager.pageSize = TPS.AzureSearch.getPageSize(`#tps-pagination-count-selector-ctrl`);
            // reset selected facets
            searchProps.facetsSelected = [];

            // call the azure search jquery library
            // with the props
            $(mergedProps.results.container).azuresearch(mergedProps);
        }

        if (!_self.countsAdded) {
            _self.getGroupCounts();
        }
        

    }

    // handle the page size change
    handlePageSizeSelection(_e) {

        if (!_e)
            return;

        const _self = TPS.FederatedSearch;
        // get the current active group ui key
        const activeIndexKey = document.querySelector(`#tps-federated-search-results-groups-nav-select`).value;
        // check if we have a search term
        if (TPS.AzureSearch.searchTerm !== '' && TPS.AzureSearch.searchTerm !== null && typeof TPS.AzureSearch.searchTerm !== 'undefined') {
            // get the search results with this updated page size value
            _self.getSearchResults(activeIndexKey);
        }

    }

    // Set Groups Counter
    getGroupCounts() {
        const _self = TPS.FederatedSearch;

        const searchInput = document.querySelector(`#tps-azure-input-keyword-search`);
        


        const baseRequestBody = {
            scoringProfile: 'title',
            facets: [],
            count: true,
            skip: 0,
            queryType: 'full',
            speller: 'lexicon',
            queryLanguage: 'en-us',
            searchMode: 'any',
            search: null
        }


        _self.groups.forEach((group) => {

            let searchTerm = null;
            if (searchInput) {
                
                // we will generete the search term using
                // a public function from the azure search jquery library
                searchTerm = $().sanitizeTerms(searchInput.value, group.azureOpts, group.queryType);
            }

            let groupFilter = group.filter;

            if (group.dateFields) {
                let date_f = '';

                let fromDateValue, toDateValue;
                const fromDateField = document.querySelector(group.dateFields.from.selector);
                const toDateField = document.querySelector(group.dateFields.to.selector);

                // handle the "from" date filter value
                if (fromDateField) {
                    fromDateValue = $().getDateFilterText(fromDateField.value, 'fromDate');

                    if (fromDateValue) {
                        date_f = group.dateFields.from.indexField + ' ge ' + fromDateValue.toISOString();
                    }
                }
                // handle the "to" date filter value
                if (toDateField) {
                    toDateValue = $().getDateFilterText(toDateField.value, 'toDate');
                    if (toDateValue) {
                        if (fromDateValue)
                            date_f += ' and ';
                        date_f += group.dateFields.to.indexField + ' le ' + toDateValue.toISOString();
                    }
                }
                
                if (groupFilter && date_f)
                {
                    groupFilter += ` and ${date_f}`;
                } else if (date_f)
                {
                    groupFilter = date_f;
                }

            }

            const groupBodyProps = {
                filter: groupFilter,
                search: searchTerm
            };
            
            const requestBody = Object.assign({}, baseRequestBody, groupBodyProps);
            
            const request = fetch(
                group.requestUrl,
                {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "api-key": group.accessKey,
                    "Cache-Control": "no-cache"
                },
                "body": JSON.stringify(requestBody)
                }
            );
            // make the request
            request.then((response) => {


                response.json().then((data) => {
                    var count = 0;
                    if (!data.error) {
                        count = data['@odata.count'];
                    }
                    _self.updateGroupCtaCount(group.uiKey, count);
                    _self.updateGroupSelectOptionCount(group.uiKey, count);
                });
            });

        });

        _self.countsAdded = true;

    }

    // add the total results to the CTA label
    updateGroupCtaCount(_uiKey, _count) {

        const groupCtaCount = document.querySelector(`#tps-federated-search-results-groups-nav-item-cta-count-${_uiKey}`);

        if (groupCtaCount) {
            groupCtaCount.textContent = `(${_count})`;
        }

    }

    // add the total results to the dropdown option label
    updateGroupSelectOptionCount(_uiKey, _count) {
        const groupSelectOption = document.querySelector(`.tps-federated-search-results-groups-nav-select option[value="${_uiKey}"]`);

        if (groupSelectOption) {
            const currentLabel = groupSelectOption.label;
            groupSelectOption.label = `${currentLabel} (${_count})`;
        }

    }

};
