import { slideToggle } from './slidetoggle';
import { ready, getElement, getElements } from './dom';
import { checkNotification, hideNotification } from './notification';

const shortcuts = getElement(`[data-click="shortcut-links"]`);
const quickLinks = getElement(`[data-click="quick-links"]`);
const search = getElement(`[data-click="dropdown-search"]`);
const close = getElement(`[data-click="dropdown-close"]`);
const dropdowns = getElements(`[data-click^="dropdown"]`);
const burger = getElement(`[data-click="dropdown-burger"]`);
const myopl = getElement(`[data-click="dropdown-myopl"]`);
const notificationBtn = getElement(`[data-click="notification-bar"]`);
const notification = getElement(`[data-for="notification-bar"]`);
const tabletMediaQuery = window.matchMedia('(max-width: 1023px)');
const topArticleAccordion = getElement(`[data-click="news-top-articles"]`);
const topLanguageAccordion = getElement(`[data-click="top-language-list"]`);
const accordions = getElements('[data-click^="accordion"]');
const tabletAccordions = getElements('[data-click*="tablet-accordion"]');

function verifyKey(e) {
  if (e.which === 32 || e.which === 13) {
    e.preventDefault();
    this.click();
  }
}

function closeHeaderDropdown(clicked) {
  const burgerDropdown = document.querySelector(
    `[data-for="${burger.getAttribute('data-click')}"]`,
  );
  const myoplDropdown = document.querySelector(`[data-for="${myopl.getAttribute('data-click')}"]`);
  const searchDropdown = document.querySelector(
    `[data-for="${search.getAttribute('data-click')}"]`,
    );
  if (clicked === search && burgerDropdown.classList.contains('js-is-visible')) burger.click();
  if ((clicked === 'doc' ||clicked === myopl) && myoplDropdown.classList.contains('js-is-visible')) myopl.click();
  if (clicked === burger && searchDropdown.classList.contains('js-is-visible')) search.click();
}

function captureEvent(e) {
  e.preventDefault();
  // * @param clicked Here we pull off the btn that was clicked
  let clicked = this.nodeType ? this : e.target;
  if (!clicked.getAttribute('data-click')) return;
  if (clicked === close) clicked = search;
  if (clicked === search || clicked === burger) closeHeaderDropdown(clicked);
  // * @param target Here we pull off the accordion body to toggle
  const target = document.querySelector(`[data-for="${clicked.getAttribute('data-click')}"]`);
  slideToggle(target, {
    callback: isVisible => {
      if (isVisible) {
        clicked.setAttribute('aria-expanded', 'true');
        target.classList.add('js-is-visible');
        clicked.classList.add('js-is-active');
        if (clicked.getAttribute('data-focus') && getElement(`#${clicked.getAttribute('data-focus')}`) )
          getElement(`#${clicked.getAttribute('data-focus')}`).focus();
      } else {
        clicked.setAttribute('aria-expanded', 'false');
        clicked.classList.remove('js-is-active');
        target.classList.remove('js-is-visible');
        if (clicked === notificationBtn) hideNotification();
        if (clicked.getAttribute('data-focus')) clicked.focus();
      }
    },
  });
}

const handler = () => {
    dropdowns.forEach(dropdown => dropdown.addEventListener('click', captureEvent));
    document.addEventListener('click', e => e.target !== myopl && closeHeaderDropdown('doc'));

  if (shortcuts) {
    // eslint-disable-next-line no-new
    new Accordion(shortcuts, slideToggle, tabletMediaQuery);
  }
  if (quickLinks) {
    // eslint-disable-next-line no-new
    new Accordion(quickLinks, slideToggle, tabletMediaQuery);    
  }
  if (topArticleAccordion) {
    // eslint-disable-next-line no-new
    new Accordion(topArticleAccordion, slideToggle, tabletMediaQuery);
  }
  if (topLanguageAccordion) {
    // eslint-disable-next-line no-new
    new Accordion(topLanguageAccordion, slideToggle, tabletMediaQuery);
  }
  if (accordions.length >= 1) {
    accordions.forEach(a => new Accordion(a, slideToggle));
  }
  if (tabletAccordions.length >= 1) {
    tabletAccordions.forEach(a => new Accordion(a, slideToggle, tabletMediaQuery));
  }
  if (notification) {
    checkNotification();
    notificationBtn.addEventListener('click', captureEvent);
  }
};

if (dropdowns) ready(handler);

class Accordion {
  constructor(el, callback, breakpoint) {
    this.el = el;
    this.callback = callback;
    this.breakpoint = breakpoint;
    this.focus = false;
    this.init();
  }

  init() {
    if (this.breakpoint) {
      this.breakpoint.addListener(() => this.matchMedia(this.breakpoint));
      this.matchMedia(this.breakpoint);
    } else {
      this.el.addEventListener('click', this.toggle);
      this.el.addEventListener('keydown', verifyKey);
    }
    this.target = document.querySelector(`[data-for="${this.el.dataset.click}"]`);
    this.focus = this.el.dataset.focus ? this.el.dataset.focus : false;
  }

  toggle = e => {
    e.preventDefault();
    this.callback(this.target, {
      callback: isVisible => {
        if (isVisible) {
          this.setClose();
        } else {
          this.setOpen();
        }
      },
    });
  };

  focus = () => {
    if (!this.focus) return;
    const target = document.querySelector(`${this.focus}`);
    target.focus();
  };

  setClose = () => {
    this.el.setAttribute('aria-expanded', 'true');
    this.el.classList.add('js-is-active');
    this.target.classList.add('js-is-visible');
  };

  setOpen = () => {
    this.el.setAttribute('aria-expanded', 'false');
    this.el.classList.remove('js-is-active');
    this.target.classList.remove('js-is-visible');
  };

  matchMedia = (breakpoint = this.breakpoint) => {
    if (breakpoint.matches) {
      this.el.addEventListener('click', this.toggle);
      this.el.addEventListener('keydown', verifyKey);
    } else {
      this.el.removeEventListener('click', this.toggle);
      this.el.removeEventListener('keydown', verifyKey);
    }
  };
}
