import { ready, getElement, getElements, verifyKey } from './dom';

const languagesPage = getElement('main.languages-page');

// represents the languages appear in the language river
const riverLanguages = getElements('[data-river-language]');

// represent the language list on the right navigation
const languageFilters = getElements('[data-language-filter]'); 

// represents each language river of materials
const rivers = getElements('[data-river]');

// eslint-disable-next-line no-unused-vars
let languageFilter = '';

// data read from the window, coming from .NET
const { languages, materialCategories, materials } = window;
let { topLanguages } = window;

// Removes 2 language rivers on the left pane
function clearLanguageRivers() {
  const elements = document.getElementsByClassName("language-river");
  while (elements.length > 1) {
    elements[0].remove();
  }
}

const clearLanguageFilter = () => {
  languageFilters.forEach(filter => filter.classList.remove('js-is-active'));
};

const setRiverLanguage = (clickedLang) => {
  const clickedLanguage = languages.find(el => el.language === clickedLang);
  
  topLanguages.push(clickedLanguage);
  displayLanguageRivers(topLanguages); 
}

// filter the clicked language and display its river on the left pane
function setActiveFilter(e) {  
  e.preventDefault();
  clearLanguageRivers();
  topLanguages = [];

  const clicked = this.nodeType ? this : e.target;  

  if (!clicked.classList.contains('js-is-active')) {
    clearLanguageFilter();

    // eslint-disable-next-line prefer-destructuring
    languageFilter = clicked.dataset.languageFilter;    
    clicked.classList.add('js-is-active');    
  } else {
    clearLanguageFilter();
  }

  const clickedLanguage = clicked.children[0].innerText;
  setRiverLanguage(clickedLanguage);
}

// Generates each material url and icons
function generateMaterialUrl() {
  const searchUrl = 'https://opl.bibliocommons.com/v2/search?';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < topLanguages.length; i++) {
    
    // All
    getElements('[data-all]')[i].children[0].setAttribute("href", `${ searchUrl }query=+language%3A%22${ 
      topLanguages[i].code }%22&searchType=bl&title=All+${ topLanguages[i].language }+Materials`);  
    getElements('[data-all]')[i].querySelectorAll('img.hover-hidden')[0].src = materials.find(m => m.name === "All").icon;
    getElements('[data-all]')[i].querySelectorAll('img.hover-shown')[0].src = materials.find(m => m.name === "All").iconHoverState;
     
    // New
    getElements('[data-new]')[i].children[0].setAttribute("href", `${ searchUrl }f_NEWLY_ACQUIRED=NEW_LAST_SIX_MONTHS&query=+language%3A%22${ 
      topLanguages[i].code }%22&searchType=bl&title=New+${ topLanguages[i].language }+Materials`);   
    getElements('[data-new]')[i].querySelectorAll('img.hover-hidden')[0].src = materials.find(m => m.name === "New").icon;
    getElements('[data-new]')[i].querySelectorAll('img.hover-shown')[0].src = materials.find(m => m.name === "New").iconHoverState;
        
    // Books
    getElements('[data-books]')[i].children[0].setAttribute("href", `${ searchUrl }f_FORMAT=BK&query=+language%3A%22${ 
      topLanguages[i].code }%22&searchType=bl&title=${ topLanguages[i].language }+Books`);
    getElements('[data-books]')[i].querySelectorAll('img.hover-hidden')[0].src = materials.find(m => m.name === "Books").icon;
    getElements('[data-books]')[i].querySelectorAll('img.hover-shown')[0].src = materials.find(m => m.name === "Books").iconHoverState;
      
    // DVDs
    getElements('[data-dvds]')[i].children[0].setAttribute("href", `${ searchUrl }custom_edit=false&query=isolanguage%3A%22${ 
      topLanguages[i].code }%22+formatcode%3A%28BLURAY+OR+DVD+%29&searchType=bl&suppress=true`);
    getElements('[data-dvds]')[i].querySelectorAll('img.hover-hidden')[0].src = materials.find(m => m.name === "DVDs").icon;
    getElements('[data-dvds]')[i].querySelectorAll('img.hover-shown')[0].src = materials.find(m => m.name === "DVDs").iconHoverState;
      
    // Magazines
    getElements('[data-magazines]')[i].children[0].setAttribute("href", `${ searchUrl }f_FORMAT=MAG&query=+language%3A%22${ 
      topLanguages[i].code }%22&searchType=bl&title=${ topLanguages[i].language  }+Magazines`);  
    getElements('[data-magazines]')[i].querySelectorAll('img.hover-hidden')[0].src = materials.find(m => m.name === "Magazines").icon;
    getElements('[data-magazines]')[i].querySelectorAll('img.hover-shown')[0].src = materials.find(m => m.name === "Magazines").iconHoverState;
      
    // Adults
    getElements('[data-adults]')[i].children[0].setAttribute("href", `${ searchUrl }custom_edit=false&query=isolanguage%3A%22${ 
      topLanguages[i].code }%22+audience%3A%22adult%22&searchType=bl&suppress=true`);
    getElements('[data-adults]')[i].querySelectorAll('img.hover-hidden')[0].src = materials.find(m => m.name === "Adults").icon;
    getElements('[data-adults]')[i].querySelectorAll('img.hover-shown')[0].src = materials.find(m => m.name === "Adults").iconHoverState;
    
    // Kids
    getElements('[data-kids]')[i].children[0].setAttribute("href", `${ searchUrl }custom_edit=false&query=isolanguage%3A%22${ 
      topLanguages[i].code }%22+audience%3A%22children%22&searchType=bl&suppress=true`);
    getElements('[data-kids]')[i].querySelectorAll('img.hover-hidden')[0].src = materials.find(m => m.name === "Kids").icon;
    getElements('[data-kids]')[i].querySelectorAll('img.hover-shown')[0].src = materials.find(m => m.name === "Kids").iconHoverState;
  }
}

const setMaterials = () => {
  
  // for each language river
  for (let i = 0; i < topLanguages.length; i+=1) {
  
    // find the material icons to hide
    // eslint-disable-next-line no-loop-func
    const materialsToHide = materialCategories.filter(el => !topLanguages[i].materials.includes(el));

    // for each material DOM
    for (let j = 0; j < getElements('[data-river]')[i].children.length; j+=1) {
      getElements('[data-river]')[i].children[j].style.display = 'block';

      // against each material to hide
      for (let k = 0; k < materialsToHide.length; k+=1) {
        
        // find a match to hide
        if (getElements('[data-river]')[i].children[j].getAttribute('data-container') === materialsToHide[k]) {
          getElements('[data-river]')[i].children[j].style.display = 'none';
        }        
      }
    }
  }
}

// displays language rivers on the left pane as many as the topLanguages length
function displayLanguageRivers(tops) {  
  const languageTitle = getElement('#language-title');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < tops.length; i++) {
    riverLanguages[i].innerHTML = tops[i].language;
    rivers[i].setAttribute('data-river', tops[i].language);
  }

  languageTitle.innerHTML = tops[0].language; 
  setMaterials();
  generateMaterialUrl();
}

function main() {
  displayLanguageRivers(topLanguages); 
  
  languageFilters.forEach(filter => {
    filter.addEventListener('click', setActiveFilter);
    filter.addEventListener('keydown', verifyKey);
  });
}

// Check to see that we are on the online resources page if so, init
if (languagesPage) {
  ready(main);
}