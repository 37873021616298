// import velocity from 'velocity-animate';
import { ready, getElements, getElement } from './dom';
import { initValidation, validate } from './validation';

// Variables for controlling the form flow
// const fineStatus = getElement('overview-fine');
const paymentForm = getElement('.payment-form');
const btnPayment = getElement('button[data-step="payment"]');
// Variables for validating the form

// function scrollToEl(el) {
//   const top = el.getBoundingClientRect().top - 50 + window.scrollY;
//   window.scroll({
//     top,
//     behavior: 'smooth',
//   });
// }

function checkPayment(e) {
  validateForm();
  e.preventDefault();
}

const validateForm = () => {
  const inputsToValidate = getElements('[data-validate]');
  initValidation(inputsToValidate);
  const isValid = validate(inputsToValidate);

  if (isValid) {
    const paymentAmount = document.getElementById('payment-amount').value;
    const tempResults = document.getElementById('temp-results');

    tempResults.innerHTML += `<style>#temp-results {
      padding: clear:both;float:left;width:100%;padding:1rem 0;color:#046760;}</style>This is the return of the form.<br/>Amount sent to Moneris: <strong>${paymentAmount}</strong>`;

    // TODO: Need to write a function here to pass to MONERIS
  }
};

const main = () => {
  btnPayment.addEventListener('click', checkPayment);
  // paymentForm.addEventListener('keydown', checkPayment);
};

if (paymentForm) ready(main);
