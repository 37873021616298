import { ready } from './dom';

const schoolBoard = document.querySelector(
    `select#form-SummerReadingSignUp-8a7b_SchoolBoard_SelectedValue`,
);
const school = document.querySelector(
    `select#form-SummerReadingSignUp-8a7b_School_SelectedValue`,
);


const dateRadios = Array.from(
  document.querySelectorAll('input[type="radio"][name="date-preference"]'),
);
const teamRadios = Array.from(
  document.querySelectorAll('input[type="radio"][name="team-preference"]'),
);

const enableOptions = () => {
  dateRadios.forEach(r => {
    r.checked = false;
    r.removeAttribute('disabled', true);
  });
};

const disableOptions = target => {
  enableOptions();
  const options = dateRadios.filter(r => !r.value.includes(target));
  options.forEach(o => o.setAttribute('disabled', true));
};

function onChange(e) {
  const clicked = this.nodeType ? this : e.target;
  const target = `${clicked.value.split('-')[0]}`;
  disableOptions(target);
}

const filterSelectOptions = e => {
  // They have changed the school board, so set the school value to default
  school.value = 'default';
  // Capture the value that corrisponds with the options that should show
  // Check to see if an event was called. If no event then the funciton is being initialized and the filter value is the default select option, which is currently 1
  const filterValue = e.target.querySelector(`option[value="${e.target.value}"]`).dataset.filter;
  // Capture an array of all the Options
  const allOptions = Array.from(school.querySelectorAll('option'));
  // Create a new array of the options that should show
  const optionsToShow = allOptions.filter(el => el.dataset.filter === filterValue);
  allOptions.forEach(o => {
    // If the option exists in the shown array. Show / Else hide
    if (optionsToShow.indexOf(o) >= 0) o.style.display = 'block';
    else o.style.display = 'none';
  });
};

const main = () => {
  teamRadios.forEach(r => r.addEventListener('change', onChange));
};

if (dateRadios) ready(main);
if (schoolBoard) {
  schoolBoard.addEventListener('change', filterSelectOptions);
}

/*
 *This module is responsible for disabling certain inputs when another input has been selected
 *This is currently being used on the battle of the books form
 */
