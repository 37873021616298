import { ready, getElement } from './dom';

const modalPopup = getElement('.popup-modal');

const popModal = () => {
  const modalTriggers = document.querySelectorAll('.popup-trigger');
  // const modalCloseTrigger = document.querySelector('.popup-modal__close');
  const bodyBlackout = document.querySelector('.body-blackout');

  modalTriggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
        const { popupTrigger } = trigger.dataset;
        const popupModal = document.querySelector(`[data-popup-modal="${popupTrigger}"]`);
        const closeBtn = popupModal.querySelector('.popup-modal__close');

        popupModal.classList.add('is--visible');
        bodyBlackout.classList.add('is-blacked-out');             

        closeBtn.addEventListener('click', () => {
            popupModal.classList.remove('is--visible');
            bodyBlackout.classList.remove('is-blacked-out');
        });

        // add event listener to blackout element
        bodyBlackout.addEventListener('click', () => {
            popupModal.classList.remove('is--visible');
            bodyBlackout.classList.remove('is-blacked-out');
        });

        // a generic 'close' event that can 
        // be triggered on the modal directly
        popupModal.addEventListener('tps.modal.close', () => {
            popupModal.classList.remove('is--visible');
            bodyBlackout.classList.remove('is-blacked-out');
        });

    });
  });


};

function main() {
  popModal();
}

if (modalPopup) {
  ready(main);
}


