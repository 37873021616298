
export default class TPSEvents {

    constructor() {

        this.window = $(window);
        this.document = $(document);

    }

    // init();

    createFeaturedEventsCarousel(_selector, _opts) {

        const _self = TPS.Events;

        const _wrapper = document.querySelector(_selector);

        const _swiper = _wrapper.swiper ? _wrapper.swiper : undefined;

        // if the swiper exists:
        if (_swiper) {



            _self.getFeaturedEvents(_opts).then(data => {

                data.value.forEach((_item, _index, _array) => {
                    

                    _swiper.addSlide(_index, _self.createFeaturedEventItem(_item, _index));

                });

            });

        }

    }

    async getFeaturedEvents(_opts) {
        
        const _now = new Date();
        _now.setHours(0, 0, 0, 0);
        
        const _params = {
            top: 12,
            count: "true",
            search: "",
            filter: `EventGlobalFeatured eq true and EventPrivate eq false and EventModified eq 'not_modified' and EventStart ge ${_now.toISOString()} and (EventPublishDate eq null or EventPublishDate le ${_now.toISOString()})`, //EventGlobalFeatured
            orderby: "EventStart asc"
        }

        const _settings = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "api-key": _opts.azureSearch.key,
                "Cache-Control": "no-cache",
            },
            body: JSON.stringify(_params)
        }

        const response = await fetch(_opts.azureSearch.url, _settings);

        return response.json();


    }

    createFeaturedEventItem(_item, _index) {

        //const _html = `<div>${_item.title}</div>`;
        const _ages = _item.EventAges.length > 0 ? _item.EventAges.toString().replace(',', ', ') : '';
        const _location = _item.EventLocationName;
        const _roomName = _item.EventRoomName;
        const _subTitle = _item.subtitle ? `<div class="program-item-subtitle">${_item.subtitle}</div>` : '';

        const _locationText = _roomName ? `${_location} - ${_roomName}` : _location;
        const _startDate = new Date(_item.EventStart);
        const _startMonthLabel = TPS.AzureSearch.months_short[_startDate.getMonth()];
        // const _startDayLabel = TPS.AzureSearch.days_short[_startDate.getDay()];
        const _startDayLabel = TPS.AzureSearch.days[_startDate.getDay()];
        const _startHoursPrim = _startDate.getHours();
        const _startMinsPrim = _startDate.getMinutes();
        const _startHours = _startHoursPrim > 12 ? _startHoursPrim - 12 : _startHoursPrim;
        const _startMin = _startMinsPrim < 10 ? '0' + _startMinsPrim : _startMinsPrim;
        const _startTimeLabel = `${_startHours}:${_startMin} ${_startHoursPrim >= 12 ? 'pm' : 'am'}`;
        let _endDayLabel = ``;
        let _endTimeLabel = ``;

        if (_item.EventEnd) {
            const _endDate = new Date(_item.EventEnd);
            const _endHoursPrim = _endDate.getHours();
            const _endMinsPrim = _endDate.getMinutes();
            const _endHours = _endHoursPrim > 12 ? _endHoursPrim - 12 : _endHoursPrim;
            const _endMin = _endMinsPrim < 10 ? '0' + _endMinsPrim : _endMinsPrim;
            _endTimeLabel = ` - ${_endHours}:${_endMin} ${_endHoursPrim >= 12 ? 'pm' : 'am'}`

            _endDayLabel = ``;

            if (TPS.AzureSearch.days_short[_startDate.getDay()] !== _startDayLabel && _startDate.getDate() !== _endDate.getDate()) {
                _endDayLabel = ` - ${TPS.AzureSearch.days_short[_endDate.getDay()]} ${_endDate.getDate()}`;
            }

        }

        const _html = `
                    <div class="container swiper-slide">
                        <!-- Program Card Starts -->
                        <div class="program " data-for="program-button-${_index}">
                            <!-- Front of Program card starts -->
                            <div class="frontside">
                                <div class="header">
                                    <div class="date">${_startDate.getDate()}</div>
                                    <div class="month">${_startMonthLabel}</div>
                                </div>
                                <div class="body">
                                    <a href="${_item.url}" role="presentation"><strong>${_item.title}</strong></a>
                                    <div class="time"><img src="/Content/assets/img/time.png?r=2" alt="Time" /> ${_startTimeLabel}${_endTimeLabel}</div>
                                    <div class="location"><img src="/Content/assets/img/location.png?r=2" alt="Location" /> ${_locationText}</div>
                                </div>
                            </div>
                            <!-- Front of Program card ends -->
                        </div>
                        <!-- Program Card Ends -->
                    </div>
                    `;

        return _html

    }

    toggleFeaturedEventState(e) {
        //console.log(e);

        const _eventCard = e.target.closest('.program');

        if (!_eventCard.classList.contains('is-active')) {
            _eventCard.classList.add('is-active');
        } else {
            _eventCard.classList.remove('is-active');
        }
    }
}
