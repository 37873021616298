

export default class TPSAzureSearch {

    constructor() {

        this.window = $(window);
        this.document = $(document);
        this.days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        this.days_short = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.months_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        this.searchTerm = '';
        this.props = {
            urlBase: '/'
        };
        this.datePicker = {};
        this.applyingDates = false;
        
    }

    // init() {}

    getSearchResults(_selector, _opts) {
        const _self = TPS.AzureSearch;
        _self.props = _opts;
        
        if (_opts.dates && _opts.dates.hasDates) {
            _self.setupDatePicker(_opts.dates);
        }

        if (window.TPS_AZSearch) {
            window.TPS_AZSearch = $(_selector).azuresearch(_opts);
        } else {
            $(_selector).azuresearch(_opts);
        }

        _self.setupFilterAccordions();

    }

    /**
    a function used to make additional requests,
    to get some facets from the index, independant of their filtering.
    most imporatntly, for OR type facets,
    where the first facet item of a field filters the results for that facet,
    then additional facets of that type clicked are combined

        i.e. All Events that have Event Type A OR Event Type B as their configured event location

        @param {object} d : params returned from original $(selector).azuresearch() request
        fopts: facet options object
        @param {object} fopts : object of facet options for rendering the facets returned
        @param {array} fd : facet dictionary array. i.e.: [{ label : "Position", fieldName: 'biopositiongroup', params: "sort:count,count:999", tagname: "checkbox", operator: "or" }];
        @param {object} s : azuresearch object returned by the $(selector).azuresearch() plugin
        @param {function} cb : optional callback function, called after ajax request

    */

    getAdditionalFacets(d, fopts, fd, s, cb) {
        
        // return if there is not enough info
        // to proceed
        if (!d || !fopts || (!fd && fd.length > 0) || !s)
            return;

        let _filter = "";
        let _facets = [];

        // for each 
        $.each(fd, function (_fk, _fv) {
            _facets.push(`${_fv.fieldName},${_fv.params}`);


            if (d.groupedFacets !== null && typeof d.groupedFacets.or !== 'undefined') {
                /*
                // remove all OR type filters so that those facets can still appear
                $.each(d.groupedFacets.or, function (_k, _v) {
                    
                    if (_k == _fv.fieldName) {

                        // check to see if the first filter in 
                        // the request string is and or filter
                        let _replaceStr = _filter.indexOf(` and (${_k}`) === -1 ? '(' : ' and (';
                        let _replaceInd = 0;

                        $.each(_v, function (_kk, _vv) {
                            if (_replaceInd > 0) {
                                _replaceStr += ' or ';
                            }
                            _replaceStr += `${_k}/any(m: m eq '${_vv}')`;
                            _replaceInd++;
                        });
                        _replaceStr += ')';

                        // remove the OR filter for the specified field
                        // and request the search index again with all but this filter
                        // applied so we can get facets for this field, independant of any
                        // filtering applied to the results set
                        _filter = _filter.replace(_replaceStr, '');
                    }
                });
                */
            }
        });
        
        // set the search request params
        let _params = {
            search: d.params.search ? d.params.search : "*",
            facets: _facets,
            count: true,
            top: 0,
            skip: 0,
            filter: _filter,
            orderby: d.params.orderby ? d.params.orderby : "",
            queryType: d.params.search ? d.params.queryType : "simple",
            searchMode: d.params.search ? d.params.searchMode : "all"
        };

        // make the ajax search request
        var _settings = {
            "crossDomain": true,
            "url": d.azureKeys.url,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "api-key": d.azureKeys.key,
                "Cache-Control": "no-cache"
            },
            "data": JSON.stringify(_params)
        };


        $.ajax(_settings).done(function (response) {
            // call public function from $(selector).azuresearch() 
            // to render facets based on fopts
            s.loadAdditionalFacets(response, fopts, fd);
            // if it exists, call the cb function
            if (typeof cb === 'function')
                cb.call();
        });

    }

    formatTemplate(_item, _key) {

        const _self = TPS.AzureSearch;
        const _urlBase = _self.props.urlBase.slice(0, -1);

        let _itemHTML = '';

        switch (_key) {
            case 'opl':
                _itemHTML = _self.renderOplResult(_item, _urlBase);
                break;
            case 'communico':
                _itemHTML = _self.renderCommunicoResult(_item);
                break;
            case 'resource':
                _itemHTML = _self.renderResourceResult(_item);
                break;
            case 'sirsi':
                _itemHTML = _self.renderSirsiResult(_item);
                break;
            default:
                _itemHTML = '';

        }

        return _itemHTML;

    }

    renderOplResult(_item, _urlBase) {
        const _self = TPS.AzureSearch;

        
        const _summaryText = _item.summary ? `<div class="tps-federated-search-section-results-item-description">${_item.summary}</div>` : '';

        const _url = _item.classname === 'oplcustom.resource' ? _item.resourcelink : _urlBase + _item.nodealiaspath;

        const _itemHTML = `<a href="${_url}" target="_blank" class="tps-federated-search-section-results-item opl">
                    <div class="tps-federated-search-section-results-item-details">
                        <div class="tps-federated-search-section-results-item-title">${_item.documentname}</div>
                        ${_summaryText}
                        <div class="tps-federated-search-section-results-item-link">View the Page</div>
                    </div>
                </a>`;

        return _itemHTML;

    }
    
    renderResourceResult(_item) {
        const _self = TPS.AzureSearch;

        // View the Page  -/-  Sign in to view  -/-  Renew your card to view  -/-  Restricted access, please contact OPL
        //

        let _itemLabel = _self.props.results.labels.itemLabels['public'];
        let _itemUrl = _item.resourcelink;
        let _itemTarget = `target="_blank"`;
        let _itemClass = 'tps-azure-search-results-item opl';
        // check if the cardDetail object exists + the resource is gated
        if (_self.props._c && _item.resourcerequiressso === 'true') {

            if (!_self.props._c._a) {
                _itemLabel = _self.props.results.labels.itemLabels['public_gated'];
                _itemUrl = _self.props.results.labels.gatedItemUrls['public_gated'];
            } else if (_self.props._c._e) {
                _itemLabel = _self.props.results.labels.itemLabels['gated_expired'];
                _itemUrl = _self.props.results.labels.gatedItemUrls['gated_expired'];
                _itemClass += ' item-unavailable'
            } else if (_self.props._c._b) {
                _itemLabel = _self.props.results.labels.itemLabels['gated_restricted'];
                _itemUrl = 'javascript:void(0)';
                _itemTarget = ``;
                _itemClass += ' item-restricted'
            }

        }
        
        const _summaryText = _item.summary ? `<div class="tps-azure-search-results-item-description">${_item.summary}</div>` : '';
        
        const _itemHTML = `<a href="${_itemUrl}" ${_itemTarget} class="${_itemClass}">
                    <div class="tps-azure-search-results-item-details">
                        <div class="tps-azure-search-results-item-title">${_item.documentname}</div>
                        ${_summaryText}
                        <div class="tps-azure-search-results-item-link">${_itemLabel}</div>
                    </div>
                </a>`;

        return _itemHTML;

    }

    renderCommunicoResult(_item) {
        const _self = TPS.AzureSearch;

        const _startDate = new Date(_item.EventStart);
        const _currentDate = new Date();
        const _eventDate = _self.__helperEventDateString(_item);
        const _isPastEvent = _startDate < _currentDate;
        const _location = _item.EventLocationName;
        const _roomName = _item.EventRoomName;
        const _locationText = _roomName ? `${_location} - ${_roomName}` : _location;
        const _ages = _item.EventAges.length > 0 ? _item.EventAges.toString().replace(',', ', ') : '';
        const _type = _item.EventType.length > 0 ? _item.EventType.toString().replace(',', ', ') : '';

        const _subTitle = _item.subtitle ? `<div class="tps-azure-search-results-item-subtitle">${_item.subtitle}</div>` : '';

        const _agesText = _ages != '' ? `<div class="tps-azure-search-results-item-ages"><strong>Ages:</strong> ${_ages}</div>` : '';
        //const _typeText = _type != '' ? `<div class="tps-azure-search-results-item-type"><strong>Event Type:</strong> ${_type}</div>` : '';
        const _pastEventText = _isPastEvent ? `<div class="tps-azure-search-results-item-tag">Past Event</div>` : '';

        const _registrationOpen = new Date(_item.EventRegistrationOpens);
        const _registrationClose = new Date(_item.EventRegistrationCloses);
        const _isRegistrationOpen = _registrationOpen < _currentDate && _registrationClose > _currentDate;

        const _buttonLabel = _item.EventRegistration || _item.EventThirdpartyRegistration || _isRegistrationOpen ? 'Register Here' : 'Learn More';


        const _html = `
                <a href="${_item.url}" target="_blank" class="tps-azure-search-results-item results-card ${_isPastEvent ? 'past-event' : 'upcoming-event'}">
                    <div class="tps-azure-search-results-item-details">
                        <div class="tps-azure-search-results-item-title">${_item.title}</div>
                        ${_subTitle}
                        ${_pastEventText}
                        <div class="tps-azure-search-results-item-date" data-date="${_startDate.toLocaleTimeString()}">${_eventDate}</div>
                        <div class="tps-azure-search-results-item-location">${_locationText}</div>
                        ${_agesText}
                    </div>
                    <div class="tps-azure-search-results-item-actions">
                     <div class="tps-azure-search-results-item-link btn cta yellow ghost">${_buttonLabel}</div>
                    </div>
                </a>
            `;

        return _html;

    }

    renderSirsiResult(_item) {

        const _self = TPS.AzureSearch;
        const _bookFormats = ['BB', 'BOOK', 'EBOOK', 'EMAG', 'EREF', 'GR', 'KIT', 'LP', 'MAG', 'MAP', 'PB', 'UNKNOWN'];
        const _moviesFormats = ['BLURAY', 'DVD', 'EVIDEO', 'GAME', 'MISC'];
        const _musicFormats = ['BCD', 'CD', 'EAUDIO', 'EMUSIC', 'MP3', 'TB'];
        let _img_src = _item.image;
        
        if (!_img_src) {
            // check what format group, to choose the default image
            const img_grp = _bookFormats.indexOf(_item.mediaformatid) > -1 ? 'BOOKS' : _moviesFormats.indexOf(_item.mediaformatid) > -1 ? 'MOVIES' : _musicFormats.indexOf(_item.mediaformatid) > -1 ? 'MUSIC' : '';

            // if the item does not have an image in the index
            // and its format is in one of the above groups
            // then show the default image configured in kentico
            if (img_grp) {
                _img_src = _self.props.defaultImages[img_grp] ? _self.props.defaultImages[img_grp] : '';
            }
        }

        const _img = _img_src ? `<img src="${_img_src}" class="tps-seo-image show-for-sr" alt="" />` : '';
        const _img_bg = _img_src ? `style="background-image: url('${_img_src}');"` : '';

        const _authors = _item.mediaauthors ? `<div class="tps-federated-search-section-results-item-authors">${_item.mediaauthors}</div>` : '';

        const _date = _item.mediapublicationdate ? `<div class="tps-federated-search-section-results-item-year"><strong>Year:</strong> ${_item.mediapublicationdate}</div>` : ''

        const _subtitle = _item.subtitle ? `<div class="tps-federated-search-section-results-item-subtitle">${_item.subtitle}</div>` : '';

        const _html = `
                <a href="${_item.url}" target="_blank" class="tps-federated-search-section-results-item sirsi">
                    <div class="tps-federated-search-section-results-item-image-wrapper">
                        <div class="tps-federated-search-section-results-item-image" ${_img_bg}>
                            ${_img}
                        </div>
                    </div>
                    <div class="tps-federated-search-section-results-item-details">
                        <div class="tps-federated-search-section-results-item-title">${_item.title}</div>
                        ${_subtitle}
                        ${_authors}

                        <div class="tps-federated-search-section-results-item-details-inner">
                            <div class="tps-federated-search-section-results-item-format"><strong>Format:</strong> ${_item.mediaformatname}</div>
                            ${_date}
                        </div>
                        <div class="tps-federated-search-section-results-item-availability"><strong>Availability:</strong> ${_item.mediaavailability}</div>
                        <div class="tps-federated-search-section-results-item-link">View Details</div>
                    </div>
                </a>
            `;

        return _html;

    }

    toggleLayout(_selector, _toggleValue, _elem) {


        const _trigger = $(_elem);

        // if the trigger is already active, do nothing
        if (_trigger.hasClass('active')) {
            return false;
        } else {

            // remove the active class from all controls
            $('.tps-azure-search-controls-layout-toggle').removeClass('active');

            const _target = $(_selector);

            if (!_target.hasClass(_toggleValue)) {
                _target.addClass(_toggleValue);
            } else {
                _target.removeClass(_toggleValue);
            }

            _trigger.addClass('active');

        }

    }

    clearAllFilters() {
        const _self = TPS.AzureSearch;

        $('.tps-azure-search-active-facets .clear-all-facets').trigger('click');

        _self.applyingDates = true;
        $.each(_self.datePicker, function (k, v) {
            v.clearSelection();
        });

    }

    setupFilterAccordions() {

        $(document).on('click', '[data-tps-facet-accordion]', function () {

            const _target = $(this).parents('.tps-facet-accordion-wrapper');

            if (_target.hasClass('expanded')) {
                _target.removeClass('expanded');
            } else {
                _target.addClass('expanded');
            }


        });

    }

    setupDatePicker(_opts) {

        const _self = TPS.AzureSearch;

        const _fromElem = document.querySelector(_opts.fields.from.selector);

        _self.datePicker['from'] = TPS.Utilities.initLitePicker(_fromElem);

        let _toElem = null;
        if (_opts.fields.to) {
            _toElem = document.querySelector(_opts.fields.to.selector);
            _self.datePicker['to'] = TPS.Utilities.initLitePicker(_toElem);
        }

        $.each(_self.datePicker, function (k, v) {
            
            const _target = $(v.options.element);
            v.on('selected', function () {
                if (!_self.applyingDates) {
                    _target.trigger('change');
                    $('.tps-azure-search-controls-filter ').removeClass('active');
                } else {
                    _self.applyingDates = false;
                }
            });

            v.on('clear:selection', function() {
                if (!_self.applyingDates) {
                    _target.trigger('change');
                    $('.tps-azure-search-controls-filter ').removeClass('active');
                } else {
                    _self.applyingDates = false;
                }
            });

            
        });
        
    }

    

    resetSearch(e) {
        const _self = TPS.AzureSearch;
        const _form = e.target;
        console.log(e);

        // prevent default action so fields can be cleared manually
        e.preventDefault();

        // reset the fields manually
        $(_form.elements).each(function (_k, _v) {

            if (_v.type === 'radio' || _v.type === 'checkbox') {
                _v['checked'] = false;
            } else if (_v.type !== 'reset' && _v.type !== 'submit') {
                _v.value = '';
            }

        });

        _form.submit();

    }

    setDate(_e, _date) {

        

        if (!_date)
            return;

        try {
            const _self = TPS.AzureSearch;
            const _currentDate = new Date();
            const _start = _currentDate.setHours(0, 0, 0, 0);


            if (_date === 'today') {
                const _end = _currentDate.setHours(23, 59, 59, 999);
                _self.applyingDates = true;
                _self.datePicker['from'].setDate(_start);
                _self.datePicker['to'].setDate(_end);

            } else if (_date === 'upcoming') {
                _self.applyingDates = true;
                _self.datePicker['from'].setDate(_start);
                _self.datePicker['to'].clearSelection();  

            } else if (_date === 'all') {
                _self.applyingDates = true;
                _self.datePicker['from'].clearSelection();
                _self.datePicker['to'].clearSelection();                

            }

            _self.setActiveDateFilter(_e.target);
            
        }
        catch (error) {
            console.log(error);
        }


    }

    setActiveDateFilter(_elem) {
        $('.tps-azure-search-controls-filter ').removeClass('active');
        $(_elem).addClass('active');
    }

    __helperEventDateString(_item) {
        const _self = TPS.AzureSearch;

        const _startDate = new Date(_item.EventStart);
        const _startHoursPrim = _startDate.getHours();
        const _startMinsPrim = _startDate.getMinutes();
        const _startHours = _startHoursPrim > 12 ? _startHoursPrim - 12 : _startHoursPrim;
        const _startMin = _startMinsPrim < 10 ? '0' + _startMinsPrim : _startMinsPrim;

        let _eventDateString = `${_self.days[_startDate.getDay()]} ${_self.months[_startDate.getMonth()]} ${_startDate.getDate()}, ${_startDate.getFullYear()}, ${_startHours}:${_startMin}${_startHoursPrim >= 12 ? 'pm' : 'am'}`;

        if (_item.EventEnd) {
            const _endDate = new Date(_item.EventEnd);
            const _endHoursPrim = _endDate.getHours();
            const _endMinsPrim = _endDate.getMinutes();
            const _endHours = _endHoursPrim > 12 ? _endHoursPrim - 12 : _endHoursPrim;
            const _endMin = _endMinsPrim < 10 ? '0' + _endMinsPrim : _endMinsPrim;

            _eventDateString += ` - ${_endHours}:${_endMin}${_endHoursPrim >= 12 ? 'pm' : 'am'}`;
        }

        return _eventDateString;
    }


}
